import MuiAccordions from '../../UI/Accordion';
import AddTile from '../../UI/AddTile';
import SelectInput from '../../UI/SelectInput';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {
    currencies
} from '../../util/constants';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MapIcon from '@mui/icons-material/Map';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { types } from '../../util/validators';

import './SidePanel.css';

export default function FormPanel (props) {
    const size = props.loc === "side" ? "small" : "normal";
    return (
        <div className={`form-panel ${props.loc}-panel main-grid-${props.loc}`}>
            <div className={`${props.loc}-step-container`}>
                <div>
                    <div className="panel-header">
                        <h2>Options</h2>
                    </div>
                    <div className="step panel-group">
                        <Stack spacing={1}>
                            <FormControlLabel
                                title="Toggle bin usage optimization (very performance heavy). When untoggled, the packer will follow the provided bin order."
                                control={
                                    <Switch
                                        checked={!!props.options.optimizePacking}
                                        onChange={e => props.handleInput("option", 0, "optimizePacking", e.target.checked, types.options.optimizePacking.type)}
                                        color="secondary"
                                    />
                                }
                                label={`Minimize bin use (${!!props.options.optimizePacking ? "on" : "off"})`}
                                color="secondary"
                            />
                            <SelectInput
                                label="Currency"
                                id="bin-cur-option"
                                size={size}
                                margin="dense"
                                color="secondary"
                                options={Object.keys(currencies).map(key => {return {key: key, title: currencies[key].name};})}
                                value={props.options.currency}
                                onChange={val => props.handleInput("option", 0, "currency", val, types.options.currency.type)}
                                useIdx={false}
                                error={!props.inputValidation.options.currency.status}
                            />
                            <TextField
                                label="VAT"
                                id="bin-vat-option"
                                size={size}
                                margin="dense"
                                color="secondary"
                                value={props.options.vat}
                                onChange={e => props.handleInput("option", 0, "vat", e.target.value, types.options.vat.type)}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">/1{props.options.currency}</InputAdornment>,
                                }}
                                error={!props.inputValidation.options.vat.status}
                            />
                            <Button size={size} variant="contained" startIcon={<RestartAltIcon />} onClick={props.setBinsToDefault} title="Automatically sets VAT and propellant price for each bin according to selected currency">Set default prices</Button>
                            <Button size={size} variant="contained" startIcon={<MapIcon />} onClick={props.handleMapSelection}>Compute distance</Button>
                            <Button size={size} variant="contained" startIcon={<DashboardIcon />} onClick={props.handleHistorySelection}>Packing manager</Button>
                            <Button size={size} variant="contained" startIcon={<SaveIcon />} onClick={props.handleToggleSave}>Save current</Button>
                            <Button size={size} variant="text" color="secondary" startIcon={<CancelIcon />} onClick={props.handleKillWorker} disabled={props.calculatorStatus?.status !== "active"}>Kill current process</Button>
                        </Stack>
                    </div>
                </div>
                <div className="grid-child-grow-medium-xxl">
                    <div className="panel-header">
                        <h2>Cargo</h2>
                    </div>
                    <MuiAccordions
                        size={size}
                        content={props.cargo}
                        handleDeleteCargo={props.handleDeleteCargo}
                        handleInput={props.handleInput}
                        inputValidation={props.inputValidation}
                        updateActiveItem={props.setActiveCargo}
                        activeItem={props.activeCargo}
                        globalDisable={false}
                    />
                    <AddTile onClick={props.handleAddCargo}/>
                </div>
                <div className="grid-child-grow-medium-lg">
                    <div className="panel-header">
                        <h2>Bins</h2>
                    </div>
                    <MuiAccordions
                        size={size}
                        content={props.bins}
                        handleDeleteBin={props.handleDeleteBin}
                        options={props.options}
                        handleInput={props.handleInput}
                        inputValidation={props.inputValidation}
                        updateActiveItem={props.setActiveBin}
                        activeItem={props.activeBin}
                        globalDisable={false}
                    />
                    <AddTile onClick={props.handleAddBin}/>
                </div>
            </div>
        </div>
    );
};
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import ColorPicker from '../../UI/ColorPicker';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import SelectInput from '../../UI/SelectInput';
import { types } from '../../util/validators';

const cargoTypes = [
    {
        key: "box",
        title: "Box"
    },{
        key: "bag",
        title: "Bag"
    },{
        key: "pallet",
        title: "Pallet"
    }
];

export default function CargoStep (props) {

    const handleChange = (key, val, type) => props.handleSetInput(props.item._, props.i, key, val, type);
    try{
        return (
            <div className="step">
                <Stack>
                    <Button size={props.size} variant="text" color="secondary" startIcon={<DeleteIcon />} onClick={() => props.onDelete(props.i)}>Delete</Button>
                    <ColorPicker
                        label="Set color"
                        id={"cargo-color-" + props.i}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.color}
                        onChange={val => handleChange("color", val, types.cargo.color.type)}
                        error={!props.inputValidation.cargo[props.i].color.status}
                    />
                    <SelectInput
                        label="Type"
                        id={"cargo-type-" + props.i}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        options={cargoTypes}
                        value={props.item.type}
                        onChange={val => handleChange("type", val, types.cargo.type.type)}
                        useIdx={false}
                        error={!props.inputValidation.cargo[props.i].type.status}
                    />
                    <TextField
                        label="Length"
                        id={"cargo-x-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            min: 1
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.x}
                        onChange={e => handleChange("x", e.target.value, types.cargo.x.type)}
                        type="number"
                        error={!props.inputValidation.cargo[props.i].x.status}
                    />
                    <TextField
                        label="Width"
                        id={"cargo-z-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.z}
                        onChange={e => handleChange("z", e.target.value, types.cargo.z.type)}
                        type="number"
                        error={!props.inputValidation.cargo[props.i].z.status}
                    />
                    <TextField
                        label="Height"
                        id={"cargo-y-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.y}
                        onChange={e => handleChange("y", e.target.value, types.cargo.y.type)}
                        type="number"
                        error={!props.inputValidation.cargo[props.i].y.status}
                    />
                    <TextField
                        label="Weight"
                        id={"cargo-kg-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.strkg}
                        onChange={e => handleChange("strkg", e.target.value, types.cargo.strkg.type)}
                        type="number"
                        error={!props.inputValidation.cargo[props.i].strkg.status}
                    />
                    <TextField
                        label="Quantity"
                        id={"cargo-n-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">pc</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.strn}
                        onChange={e => handleChange("strn", e.target.value, types.cargo.strn.type)}
                        type="number"
                        error={!props.inputValidation.cargo[props.i].strn.status}
                    />
                </Stack>
            </div>
        );
    }catch(err){ return (<div className="step"><span>Preparing data...</span></div>); }
};
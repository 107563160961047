import {
    fuelOptions,
    profiles
} from "./constants";

export const getFuelUnit = carb => {
    const u = fuelOptions.filter(item => item.key === carb);
    if(!u.length) return "";
    return u[0].unit;
};

const kgFormat = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "kg" },
      { value: 1e3, symbol: "t" },
      { value: 1e6, symbol: "kt" },
      { value: 1e9, symbol: "Mt" },
      { value: 1e12, symbol: "Gt" },
      { value: 1e15, symbol: "Tt" },
      { value: 1e18, symbol: "Pt" },
      { value: 1e21, symbol: "Em" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};

const mmFormat = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "mm" },
      { value: 1e1, symbol: "cm" },
      { value: 1e3, symbol: "m" },
      { value: 1e6, symbol: "km" },
      { value: 1e9, symbol: "Mm" },
      { value: 1e12, symbol: "Gm" },
      { value: 1e15, symbol: "Tm" },
      { value: 1e18, symbol: "Pm" },
      { value: 1e21, symbol: "Em" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};

const shortFormat = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};

export const ntrs = (num, dec) => {
    if(!dec) return shortFormat(num, 2);
    if(dec === "mm") return mmFormat(num, 2);
    if(dec === "kg") return kgFormat(num, 2);
    const n = parseFloat(num);
    if(!isNaN(n)){
        let z = n < 0 ? "-" : "";
        let a = n.toFixed(dec - 1);
        let b = a.toString().split(".");
        let c = b[0].split("").reverse();
        let d = [];
        while(c.length){
            let e = c.length < 3 ? c.splice(0, c.length) : c.splice(0, 3);
            d.push(e.reverse().toString().replace(/,/g,""));
        }
        return b[1] === undefined ? z + d.reverse().toString() : z + d.reverse().toString() + "." + b[1].toString();
    }else{
        const str = num.toString();
        return str !== "NaN" ? str : "0" ;
    }
};

export const selectProfile = idx => profiles[idx].binItem;

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toKm = 1.609344;
    const radlat1 = Math.PI * parseFloat(lat1) / 180;
    const radlat2 = Math.PI * parseFloat(lat2) / 180;
    const theta = parseFloat(lon1) - parseFloat(lon2);
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = Math.round(dist * 60 * 1.1515 * toKm);
    return dist;
};

export const secondsToHms = d => {
    const s = parseInt(d);
    if(isNaN(s)) return "?";
    const ts = [
        {
            t: Math.floor(s / 60 / 60 / 24 / 7 / 4.3 / 12),
            unit: "Y"
        },{
            t: Math.floor(s / 60 / 60 / 24 / 7 / 4.3) % 12,
            unit: "M"
        },{
            t: Math.floor(s / 60 / 60 / 24 / 7) % 4.3,
            unit: "W"
        },{
            t: Math.floor(s / 60 / 60 / 24) % 7,
            unit: "d"
        },{
            t: Math.floor(s / 60 / 60) % 24,
            unit: "h"
        },{
            t: Math.floor(s / 60) % 60,
            unit: "m"
        }
    ];
    return ts.filter(item => !!Math.floor(item.t)).slice(0, 3).map(item => (Math.floor(item.t) + item.unit)).join(', ') || "?";
};

export const sanitize = (val, type) => {
    try{
        switch(type){
            case "string": return val.toString();
            case "int":
                const int = Math.abs(parseInt(val));
                return isNaN(int) ? "" : int;
            case "float":
                const float = Math.abs(parseFloat(parseFloat(val).toFixed(3)));
                return isNaN(float) ? "" : float;
            case "boolean": return !!val;
            default: return null;
        }
    }catch(e){
        return null;
    }
};
export const changelogText = {
    h: "Changelog",
    content: [
        {
            h: "5/2023",
            l: ["maintenance"]
        },{
            h: "4/2023",
            l: ["maintenance"]
        },{
            h: "3/2023",
            l: ["maintenance"]
        },{
            h: "2/2023",
            l: ["maintenance"]
        },{
            h: "1/2023",
            l: ["maintenance"]
        },{
            h: "12/2022",
            l: ["maintenance"]
        },{
            h: "11/2022",
            l: ["maintenance"]
        },{
            h: "10/2022",
            l: ["fixed error causing a blank page when navigating from any secondary page to the calculator page while having actively selected a cargo or bin item"]
        },{
            h: "9/2022",
            l: ["maintenance"]
        },{
            h: "8/2022",
            l: ["improved UI performance", "added 3D orientation helper", "allow cargo preview while single box is already selected or animation is running", "greater randomness in color attribution"]
        },{
            h: "7/2022",
            l: ["updated documentation", "select/unselect single cargo directly from 3D model", "display 3D bin packer status & packing progress", "added ability to manually kill a computation process", "enabled 360° rotation on y axis for 3D model (previously 180°)", "3D model grid scale set to 1m x 1m", "3D model graphical improvements", "implemented real-time single-box/bin preview", "removed unnecessary packing recomputation & 3D model rerender", "added switches instead of checkboxes for on/off toggling", "larger mobile menu", "fixed non-unique multiple bin bug", "fixed invisible results bug on safari <13.0 for mobile"]
        },{
            h: "6/2022",
            l: ["maintenance"]
        },{
            h: "5/2022",
            l: ["improved UI for large screens", "clearer documentation", "fixed incorrect rotation bug in 3D visualization", "updated default regional prices"]
        },{
            h: "4/2022",
            l: ["multi-threading (page stays responsive during heavy computation)", "3D binpacking algorithm optimization"]
        },{
            h: "3/2022",
            l: ["maintenance updates"]
        },{
            h: "2/2022",
            l: ["maintenance updates"]
        },{
            h: "1/2022",
            l: ["migrate from class based to functional components","revamped charts with visx","design overhaul","refactored & simplified UI (with the help of Material UI)","simplified usage of fleet manager & packing history","added real-time packing optimization & real-time 3D modelization","improved accessibility","automatic saving of packing configuration disabled & saving is now only performed on user's request","virtualization of lists & tables for better performance","added documentation"]
        },{
            h: "12/2021",
            l: ["3D instanciation & various render optimizations implemented (3D model is now significantly faster & handles greater loads)","design & layout modernized","easier language"]
        },{
            h: "11/2021",
            l: ["improved 3D packing helper, boxes are now transparent by default & have different shades depending on the user's selection","bug fixes","improved UI","terms updated"]
        },{
            h: "10/2021",
            l: ["optimized 3D rendering"]
        },{
            h: "9/2021",
            l: ["updated default regional prices & costs","bug fixes"]
        },{
            h: "8/2021",
            l: ["improved UI & design"]
        },{
            h: "7/2021",
            l: ["3D binpacking algorithm optimization","bug fixes"]
        },{
            h: "6/2021",
            l: ["ability to download packing results (csv) added","routing and navigation disabled by default","improved dashboard UI"]
        },{
            h: "4/2021",
            l: ["customizable multi-bin input & trip minimizer implemented","max bin limit removed"]
        },{
            h: "3/2021",
            l: ["multi-trip, multi-cargo & multi-bin packing algorithm implemented","improved dashboard UI"]
        },{
            h: "2/2021",
            l: ["user dashboard & fleet manager added (ability to save, load, modify & delete fleets)"]
        },{
            h: "1/2021",
            l: ["3D binpacking algorithm & 3D rendering optimization","implementation of intelligent routing & dynamic navigation based on vehicle type, load and road availability (shortest route, highways, traffic perturbations, etc.)"]
        },{
            h: "12/2020",
            l: ["UI & design overhaul","shortcuts added to navigation bar","Graphical data visualization implemented (recharts)"]
        },{
            h: "11/2020",
            l: ["bug fixes","terms updated","vehicle type \"drone\" added to preconfigured vehicle list"]
        },{
            h: "10/2020",
            l: ["ability to delete packing history added"]
        },{
            h: "9/2020",
            l: ["implementation of the step by step 3D packing tutorial"]
        },{
            h: "8/2020",
            l: ["customizable color palette added to cargo selection"]
        },{
            h: "7/2020",
            l: ["packing history functionality implemented"]
        },{
            h: "6/2020",
            l: ["3D binpacking algorithm GPU optimization"]
        },{
            h: "5/2020",
            l: ["optimized 3D rendering", "improved UI & accessibility"]
        },{
            h: "4/2020",
            l: ["3D bin packing algorithm optimization"]
        },{
            h: "3/2020",
            l: ["improvements to routing and navigation","improved trip cost estimation"]
        },{
            h: "2/2020",
            l: ["3D packing helper, navigation, routing added","new currencies and preconfigured commodity pricing added."]
        },{
            h: "1/2020",
            l: ["simplified UI & improved 3D bin packing algorithm"]
        },{
            h: "12/2019",
            l: ["website moved to 3dbinpacker.com"]
        },{
            h: "11/2019",
            l: ["selectable preconfigured vehicle dimensions implemented"]
        },{
            h: "10/2019",
            l: ["complete design overhaul","costs & tax estimator added"]
        },{
            h: "9/2019",
            l: ["financial advisor implemented","quote builder & tarification tools added"]
        },{
            h: "8/2019",
            l: ["profit & cost estimator implemented"]
        },{
            h: "7/2019",
            l: ["improved UI & design"]
        },{
            h: "6/2019",
            l: ["packing of different cargo sizes implemented","number of different packable types limited to six."]
        },{
            h: "5/2019",
            l: ["bug fixes", "performance fix"]
        },{
            h: "4/2019",
            l: ["bug fixes"]
        },{
            h: "3/2019",
            l: ["improved UI & design"]
        },{
            h: "2/2019",
            l: ["improvement & further optimization of the current 3D single bin packing algorithm"]
        },{
            h: "1/2019",
            l: ["bug fixes"]
        },{
            h: "12/2018",
            l: ["bug fixes"]
        },{
            h: "11/2018",
            l: ["initial launch at daminski.org"]
        }
    ]
};
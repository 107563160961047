import { profiles } from './constants';

export const generateCsv = (graphic, data) => {
    const filename = "3dbinpacker.csv";

    let csv = "3dbinpacker.com, 3D tutorial available at 3dbinpacker.com\r\n\r\n"
    + graphic.map((trip, j) =>
        "TRIP #" + (j + 1).toFixed(0) + ",,Length (mm),Height (mm),Width (mm),Tonnage(kg)\r\n"
        + "BIN #" + (data[j].strIdx + 1).toFixed(0) + "," + profiles[data[j].str.type].type.toUpperCase() + "," + data[j].str.x.toString() + "," + data[j].str.y.toString() + "," + data[j].str.z.toString() + "," + data[j].str.ton.toString() + "\r\n"
        + "Packing order, Type, Dim 1 (mm), Dim 2 (mm), Dim 3 (mm), Weight (kg), Volume (mm3), Bin Coord x (mm), Bin Coord y (mm), Bin Coord z (mm)\r\n"
        + trip.map((box, i) =>
            (i + 1).toString() + "," + box.lbl.replace(/\d/g, "").trim().toUpperCase() + "," + box.dim1.toString() + "," + box.dim2.toString() + "," + box.dim3.toString() + "," + box.kg.toString() + "," + box.vol.toString() + "," + box.cox.toString() + "," + box.coy.toString() + "," + box.coz.toString()
        ).join("\r\n")
    ).join("\r\n\r\n");
    
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if(navigator.msSaveBlob){
        navigator.msSaveBlob(blob, filename);
    }else{
        const url = URL.createObjectURL(blob);
        const dl = document.createElement("a");
        dl.style.visibility = "hidden";
        dl.href = url;
        dl.download = filename;
        document.body.appendChild(dl);
        dl.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(dl);
    }
};
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './main/App';
import { BrowserRouter } from "react-router-dom";

import './index.css';

/* Polyfill for react-virtuoso, legacy browsers */
import ResizeObserver from 'resize-observer-polyfill';
if (!window.ResizeObserver) window.ResizeObserver = ResizeObserver;

const root = createRoot(document.getElementById('root'));
root.render(
    <StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </StrictMode>
);
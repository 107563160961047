import MuiAccordions from '../../UI/Accordion';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ntrs } from '../../util/util';
import { profiles } from '../../util/constants';

import './SidePanel.css';

export default function SelectorPanel (props) {
    const size = props.loc === "side" ? "small" : "normal";
    return (
        <div className="selector-panel side-panel main-grid-side">
            {props.calculatorResults ?
                <div className="side-step-container">
                    <div>
                        <div className="panel-header">
                            <h2>Results</h2>
                        </div>
                        <div className="step panel-group">
                            <Stack spacing={1}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Packed</TableCell>
                                                <TableCell>{ntrs(props.calculatorResults.data[props.calculatorResults.data.length - 1].totNbBoxesPercentPacked, 1)}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Total cargo</TableCell>
                                                <TableCell>{ntrs(props.calculatorResults.data[0].totalNbBoxes, 1)}pc</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Trips needed</TableCell>
                                                <TableCell>{ntrs(props.calculatorResults.data.length)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Current trip</TableCell>
                                                <TableCell>#{ntrs(props.currentSelectedTrip + 1, 1)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Current bin</TableCell>
                                                <TableCell title={profiles[props.calculatorResults.data[props.currentSelectedTrip].str.type].type}>bin #{ntrs(props.calculatorResults.data[props.currentSelectedTrip].strIdx + 1, 1)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button size="small" variant="contained" startIcon={<DownloadIcon />} onClick={props.handleCsv} disabled={props.globalDisable}>Download csv</Button>
                                <Stack key="side-panel-top-1" direction="row" spacing={1}>
                                    <Button sx={{flex: "1 1 50%"}} size="small" variant="contained" startIcon={<NavigateBeforeIcon />} onClick={() => props.handleSelectTrip(props.currentSelectedTrip - 1)} disabled={props.currentSelectedTrip === 0 || props.globalDisable}>Previous</Button>
                                    <Button sx={{flex: "1 1 50%"}} size="small" variant="contained" endIcon={<NavigateNextIcon />} onClick={() => props.handleSelectTrip(props.currentSelectedTrip + 1)} disabled={props.currentSelectedTrip === props.calculatorResults.data.length - 1 || props.globalDisable}>Next</Button>
                                </Stack>
                            </Stack>
                        </div>
                        <MuiAccordions
                            size={size}
                            content={props.calculatorResults.data}
                            graphic={props.calculatorResults.graphic}
                            handleSelectTrip={props.handleSelectTrip}
                            handleSelectBox={props.handleSelectBox}
                            currentSelectedTrip={props.currentSelectedTrip}
                            currentSelectedBox={props.currentSelectedBox}
                            isIntervalPlaying={props.isIntervalPlaying}
                            handleInterval={props.handleInterval}
                            handleNav={props.handleNav}
                            globalDisable={props.globalDisable}
                        />
                    </div>
                </div>
                :
                <div className="side-step-container">
                    <div>
                        <div className="panel-header">
                            <h2>Computing</h2>
                        </div>
                        <div className="step panel-group">
                            <p>...</p>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
import './NavLink.css';

export default function NavLink ({onClick, item, selected}) {
    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();
        onClick(item.key);
    };
    return (
        <a className={`navlink${selected ? " --active" : ""}`} href={item.key || "/"} title={item.title} onClick={handleClick}>{item.title}</a>
    );
};
import { useState, useEffect } from 'react';
import CargoStep from '../components/Steps/CargoStep';
import BinStep from '../components/Steps/BinStep';
import TripStep from '../components/Steps/TripStep';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from './MuiAccordion';
import { ntrs } from '../util/util';
import './Accordion.css';

const AccordionHeader = {
  cargo: (item, idx) => (
    <div className="accordion-header flex fcol">
      <h3 className="accordion-header-h flex fvcent">
          <span>{item.type.toUpperCase()}</span>
      </h3>
      <p className="accordion-header-p">{ntrs(item.x, "mm") + "x" + ntrs(item.y, "mm") + "x" + ntrs(item.z, "mm")}</p>
      <div className="color-helper" style={{color: item.color}}></div>
    </div>
  ),
  bin: (item, idx) => (
    <div className="accordion-header flex fcol">
      <h3 className="accordion-header-h flex fvcent">
        <span>Bin&nbsp;{"#" + ntrs(idx + 1, 1)}</span>
      </h3>
      <p className="accordion-header-p">{ntrs(item.x, "mm") + "x" + ntrs(item.y, "mm") + "x" + ntrs(item.z, "mm")}</p>
    </div>
  ),
  trip: (item, idx) => (
    <div className="accordion-header flex fcol">
      <h3 className="accordion-header-h flex fvcent">
        <span>Trip&nbsp;{"#" + ntrs(idx + 1, 1)}&nbsp;-&nbsp;Bin&nbsp;{"#" + ntrs(item.strIdx + 1, 1)}</span>
      </h3>
      <p className="accordion-header-p">{`${ntrs(item.bestPackedNb)}pc - ${ntrs(item.totNbBoxesPercentPacked, 1)}% of total packed`}</p>
    </div>
  )
};



export default function MuiAccordions ({ handleSelectTrip = () => null, updateActiveItem = () => null, handleInput = () => null, handleDeleteCargo = () => null, handleDeleteBin = () => null, handleSelectBox = () => null, handleInterval = () => null, handleNav = () => null, content = [], currentSelectedBox = -1, currentSelectedTrip = -1, activeItem = "none", inputValidation = {}, size = "", options = {}, graphic = [], isIntervalPlaying = false, globalDisable = false }) {
  const [expanded, setExpanded] = useState("none");

  const handleChange = (_, panel) => (event, newExpanded) => {
    switch(_){
      case "trip": return handleSelectTrip(panel);
      default:
        const cur = newExpanded ? panel : "none";
        updateActiveItem(cur);
        return setExpanded(cur);
    }
  };

  const handleSetInput = (_, index, key, value, type) => handleInput(_, index, key, value, type);

  const handleExpanded = (_, i) => {
    switch(_){
      case "trip": return currentSelectedTrip === i;
      default: return expanded === i;
    }
  };

  useEffect(() => {
    if(activeItem === 'none') setExpanded('none');
  }, [ activeItem ]);

  return (
    <div>
        {content.map((item, i) => item &&
            <Accordion key={"accordion-" + i} expanded={handleExpanded(item._, i)} onChange={handleChange(item._, i)} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary aria-controls={`panel-${i}-content`} id={`panel-${i}-header`}>
                  {AccordionHeader[item._]?.(item, i)}
                </AccordionSummary>
                <AccordionDetails>
                    {item._ === "cargo" && <CargoStep size={size} item={item} i={i} onDelete={idx => {setExpanded("none"); handleDeleteCargo(idx);}} handleSetInput={handleSetInput} inputValidation={inputValidation}/>}
                    {item._ === "bin" && <BinStep size={size} options={options} item={item} i={i} onDelete={idx => {setExpanded("none"); handleDeleteBin(idx);}} handleSetInput={handleSetInput} inputValidation={inputValidation}/>}
                    {item._ === "trip" && <TripStep size={size} i={i} item={graphic[i]} handleSelectTrip={handleSelectTrip} handleSelectBox={handleSelectBox} currentSelectedTrip={currentSelectedTrip} currentSelectedBox={currentSelectedBox} isIntervalPlaying={isIntervalPlaying} handleInterval={handleInterval} handleNav={handleNav} globalDisable={globalDisable}/>}
                </AccordionDetails>
            </Accordion>
        )}
    </div>
  );
}
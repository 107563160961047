import SHA256 from 'crypto-js/sha256';

const historyMaxEntries = 100;

export class HistoryHandler {
    constructor(){
        this.serviceStorage = "hdzs7nsnxi8902kspmkaij361nsbxhgaqplybgsj381ksnh19ajhszainhskajuus";
    }
    #get = () => JSON.parse(window.localStorage.getItem(this.serviceStorage));
    set = (obj, storage, action) => {
        let cache = this.#get() || {map: [], packing: []};
        switch(action){
            case "add":
                if(cache[storage]){
                    cache[storage].push(obj);
                    if(cache.packing?.length > historyMaxEntries){
                        cache.packing.sort((a, b) => b.date - a.date) 
                        cache = cache.slice(0, historyMaxEntries - 1);
                    }
                }else cache[storage] = [obj];
                break;
            case "delete":
                if(storage === "packing") cache = this.#deletePackingItem(obj, cache);
                else return null;
                break;
            default: return null;
        }

        window.localStorage.setItem(this.serviceStorage, JSON.stringify(cache));
        return cache;
    }
    deleteAll = () => {
        const cache = this.#get() || {map: [], packing: []};
        cache.packing = [];
        window.localStorage.setItem(this.serviceStorage, JSON.stringify(cache));
    }
    getDistance = (a, b) => {
        const precision = 10000;
        try{
            const cache = this.#get();
            if(cache.map.length === 0) throw new Error("empty cache");

            const ra = [Math.round(a[0] * precision) / precision, Math.round(a[1] * precision) / precision];
            const rb = [Math.round(b[0] * precision) / precision, Math.round(b[1] * precision) / precision];

            for(let i = 0; i < cache.map.length; i++){
                const ria = [Math.round(cache.map[i][0][0] * precision) / precision, Math.round(cache.map[i][0][1] * precision) / precision];
                const rib = [Math.round(cache.map[i][1][0] * precision) / precision, Math.round(cache.map[i][1][1] * precision) / precision];

                if(ria[0] === ra[0] && ria[1] === ra[i] && rib[0] === rb[0] && rib[1] === rb[1]) return cache.map[i][2].dist;
                if(rib[0] === ra[0] && rib[1] === ra[i] && ria[0] === rb[0] && ria[1] === rb[1]) return cache.map[i][2].dist;
            }
            return null;
        }catch(e){
            return null;
        }
    };
    getPackings = () => {
        const cache = this.#get();
        return cache?.packing?.sort((a, b) => b.date - a.date) || [];
    };
    hash = () => SHA256(window.crypto.getRandomValues(new Uint32Array(10)).join("").toString()).toString();
    #deletePackingItem = (obj, cache) => {
        return {
            map: cache.map,
            packing: cache.packing.filter(item => item.id !== obj.id)
        };
    };
};

export class PackingSaveObject {
    constructor(name, cargo, bins, options, id){
        this.name = name || "Packing";
        this.cargo = cargo;
        this.bins = bins;
        this.options = options;
        this.id = id;
        this.date = new Date().getTime();
    }
};
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';

let timeout = null;

export default function ColorPicker (props) {
    const [color, setColor] = useState(props.value);
    const handleChange = e => {
        const val = e.target.value;
        clearTimeout(timeout);
        timeout = setTimeout(() => props.onChange(val), 1000);
        setColor(val);
    };

    useEffect(() => setColor(props.value), [props.value]);

    return (
        <TextField
            label={props.label}
            id={props.id}
            size={props.size}
            margin={props.margin}
            color={props.color}
            value={color}
            onChange={handleChange}
            type="color"
            error={props.error}
        />
    );
};
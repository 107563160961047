import {
    Scene,
    PerspectiveCamera,
    WebGLRenderer,
    AmbientLight,
    DirectionalLight,
    BasicShadowMap,
    Raycaster,
    Vector2,
    Color,
    BoxGeometry,
    Mesh,
    MeshPhongMaterial,
    Object3D,
    InstancedMesh,
    DynamicDrawUsage,
    DoubleSide,
    GridHelper,
    EventDispatcher
} from 'three';
import { ViewHelper } from './Model.viewhelper';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
    shadeHexColor,
    calculateRotation
} from './Model.util';

export class SceneHandler extends EventDispatcher {
    #frameId = null;

    constructor(){
        super();

        /* DOM container (ref) */
        this.container = null;

        /* 3D model */
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.controls = null;
        this.mouse = new Vector2(1, 1);
        this.raycaster = new Raycaster();
        this.orientationHelper = null;

        /* utils */
        this.currentSceneChildren = [];
        this.cargoShortcut = [];
        this.mouseDownTimestamp = 0;
    }

    mountScene(biggestBin, container){
        this.scene = new Scene();
        this.container = container;
        const width = container.clientWidth;
        const height = container.clientHeight;
        const biggestDim = Math.max(biggestBin.x, biggestBin.y, biggestBin.z);

        /* Light */
        this.scene.add(new AmbientLight(0x606060, 1.5));

        const dirLight = new DirectionalLight( 0xffffff, 1 );
        dirLight.color.setHSL( 0.1, 1, 0.95 );
        dirLight.position.set( -1, 1.75, 1 );
        dirLight.position.multiplyScalar( 30 );
        this.scene.add( dirLight );

        const dirLight2 = new DirectionalLight( 0xffffff, 1 );
        dirLight2.color.setHSL( 0.1, 1, 0.95 );
        dirLight2.position.set( 1, 1.75, -1.75 );
        dirLight2.position.multiplyScalar( 30 );
        this.scene.add( dirLight2 );

        /* Renderer */
        this.renderer = new WebGLRenderer({ antialias: false });
        this.renderer.setClearColor('#010001');
        this.renderer.setSize(width, height);
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = BasicShadowMap;

        /* Camera */
        this.camera = new PerspectiveCamera(75, width / height, 0.1, biggestDim * 100,);
        this.camera.position.set(
            biggestBin.x * 2,
            biggestBin.y,
            biggestBin.z * 2
        );

        /* Controls */
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.target.set(0, 0, 0);
        this.controls.screenSpacePanning = false;
        this.controls.minDistance = 0;
        /*this.controls.maxPolarAngle = (Math.PI / 2) - 0.01;*/

        /* View helper */
        this.orientationHelper = new ViewHelper(this.camera, this.renderer.domElement);
        this.orientationHelper.controls = this.controls;

        /* Mount scene */
        this.container.appendChild(this.renderer.domElement);
        this.#start();

        /* Event listeners */
        window.addEventListener('resize', this.#_event_handleResize);
        this.renderer.domElement.addEventListener('click', this.#_event_handleClick);
        this.renderer.domElement.addEventListener('mousedown', this.#_event_handleMouseDown);
    }
    unmountScene(){
        try{
            window.removeEventListener('resize', this.#_event_handleResize);
            this.renderer.domElement.removeEventListener('click', this.#_event_handleClick);
            this.renderer.domElement.removeEventListener('mousedown', this.#_event_handleMouseDown);

            if(this.scene?.children?.length) this.scene.remove(...this.scene.children);
            this.currentSceneChildren = [];
            this.container.removeChild(this.renderer.domElement);
            this.#stop();

        }catch(err){
            return null;
        }
    }
    mountChildren(selection = "all", payload = {}){
        let children = [];

        switch(selection){
            case "bin":
                const singleBin = this.#_createCube(payload.x, payload.y, payload.z, true, null,);
                const gridHelperBin = this.#_createGrid(payload.x, payload.y, payload.z);
                children = [ singleBin, gridHelperBin ];
                break;
            case "cargo":
                const singleCargo = this.#_createInstancedCube(payload.x, payload.y, payload.z, payload.color, payload.strn);
                const gridHelperCargo = this.#_createGrid(payload.x, payload.y, payload.z);
                children = [ singleCargo, gridHelperCargo ];
                break;
            default:
                const currentData = payload.data[payload.current];

                /* Container */
                const bin = this.#_createCube(currentData.pOrx, currentData.pOry, currentData.pOrz, true, null);

                /* Cargo instanciation */
                const instances = this.#_createInstances(currentData, payload.graphic[payload.current]);

                /* Grid */
                const gridHelper = this.#_createGrid(currentData.pOrx, currentData.pOry, currentData.pOrz);
                
                children = [bin, ...instances, gridHelper];
                break;
        };

        if(children.length){
            this.#unmountChildren();
            for(let i = 0; i < children.length; i++){
                this.scene.add(children[i]);
            }
            this.currentSceneChildren = children;

            this.controls.maxDistance = Math.max(children[0].geometry.parameters.width, children[0].geometry.parameters.height, children[0].geometry.parameters.depth) * 100;
        }
    }
    #unmountChildren(){
        this.scene.remove(...this.currentSceneChildren);
        this.currentSceneChildren = [];
    }
    #_createCube(x = 1, y = 1, z = 1, wireframe = true, color = null){
        const geoBlock = new BoxGeometry( x, y, z );
        const matBlock = new MeshPhongMaterial( { wireframe: wireframe } );
        if(color) matBlock.color = new Color(color);
        const block = new Mesh( geoBlock, matBlock );
        block.position.set((x / 2), (y / 2), (z / 2));
        return block;
    }
    #_createInstancedCube(x = 1, y = 1, z = 1, color = null, numInstances = 1){
        const ni = Math.min(50000, numInstances);
        const transform  = new Object3D();
        const block = this.#_createInstance(x, y, z, "box", ni, false);
        const xm = (Math.max(x, y, z, 100) * 10);
        const halfXm = xm / 2;
        const halfZ = z / 2;
        const halfX = x / 2;
        const halfY = y / 2;
        let maxItemsPerRow = Math.floor(xm / z);
        for(let i = 0, row = 0, column = 0; i < ni; i++){
            if(i === 0) transform.position.set(
                halfX,
                halfY,
                halfZ
            );
            else{
                if(column + 1 > maxItemsPerRow){
                    row++;
                    column = 0;
                }
                const w = (halfZ - halfXm) + (column * z) + (i > 1 ? Math.random() * halfZ : 0);
                transform.position.set(
                    halfX - halfXm,
                    halfY + (row * y),
                    w
                );
                column++;
            }
            transform.updateMatrix();
            if(color) block.setColorAt(i, new Color(color));
            block.setMatrixAt(i, transform.matrix);
        }
        return block;
    }
    #_createInstances(data, boxList){
        this.cargoShortcut = new Array(boxList.length);
        const instances = new Array(data.boxInstances.length);
    
        for(let i = 0; i < data.boxInstances.length; i++){
            const item = data.boxInstances[i];
            let boxIndices = [];
            for(let j = 0; j < boxList.length; j++){
                if(item.lbl === boxList[j].lbl){
                    boxIndices.push(j);
                    if(!this.cargoShortcut[j]){
                        this.cargoShortcut[j] = {
                            color: item.color,
                            darkerColor: shadeHexColor(item.color, -0.5),
                            instanceIdx: i,
                            instanceBoxIdx: 0,
                            lbl: item.lbl
                        };
                    }
                }
            }
    
            const transform = new Object3D();
            instances[i] = this.#_createInstance(item.dim1, item.dim2, item.dim3, item.lbl, boxIndices.length, true, i);
            
            for(let j = 0; j < boxIndices.length; j++){
                const box = boxList[boxIndices[j]];
                transform.position.set(
                    (parseFloat(box.packx) / 2) + box.cox,
                    (parseFloat(box.packy) / 2) + box.coy,
                    (parseFloat(box.packz) / 2) + box.coz
                );
                const rotList = calculateRotation(
                    item.dim1,
                    item.dim2,
                    item.dim3,
                    box.packx,
                    box.packy,
                    box.packz
                );
                transform.rotation.x = rotList.x;
                transform.rotation.y = rotList.y;
                transform.rotation.z = rotList.z;
                transform.updateMatrix();
                instances[i].setColorAt(j, new Color(item.color));
                instances[i].setMatrixAt(j, transform.matrix);
    
                this.cargoShortcut[boxIndices[j]].instanceBoxIdx = j;
            }
        }
        return instances;
    }
    #_createInstance(x = 1, y = 1, z = 1, label = "cargo", boxN = 1, transparency = true){
        const options = transparency ? {
            transparent: true,
            opacity: 0.8,
            side: DoubleSide,
        } : null;
        const instance = new InstancedMesh(
            new BoxGeometry( x, y, z ),
            new MeshPhongMaterial(options),
            boxN
        );
    
        instance.name = label;
        instance.instanceMatrix.setUsage( DynamicDrawUsage );
    
        return instance;
    }
    #_createGrid(x = 1, y = 1, z = 1){
        /* scale in mm */
        const scale = 1000;

        const xm = Math.max(x, y, z, 100) * 10;
        const gridHelper = new GridHelper( xm, xm / scale, new Color('#dadada'), new Color('#505050') );
        return gridHelper;
    }
    #_event_handleResize = () => {
        const width = this.container.clientWidth;
        const height = this.container.clientHeight;
        this.renderer.setSize(width, height);
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
    }
    #_event_handleClick = e => {
        e.stopPropagation();
        if((this.mouseDownTimestamp + 500) < new Date().getTime()) return null;
        if(!this.mouse || !this.raycaster || !this.camera || !this.scene) return null;
        this.mouse.x = (e.offsetX / this.container.clientWidth) * 2 - 1;
        this.mouse.y = -(e.offsetY / this.container.clientHeight) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, this.camera);
        /* only cargo instances have name */
        const intersects = this.raycaster.intersectObjects(this.scene.children.filter(item => item.type === "Mesh" && item.name));
        if(intersects.length){
            let boxIdx = -1;
            for(let i = 0; i < this.cargoShortcut.length; i++){
                if(intersects[0].object.name === this.cargoShortcut[i].lbl && intersects[0].instanceId === this.cargoShortcut[i].instanceBoxIdx){
                    boxIdx = i;
                    break;
                }
            }
            if(boxIdx > -1) this.dispatchEvent({ type: "selectbox", value: boxIdx });
        }
    }
    #_event_handleMouseDown = e => {
        e.stopPropagation();
        this.mouseDownTimestamp = new Date().getTime();
    };
    #stop = () => {
        cancelAnimationFrame(this.#frameId);
        this.#frameId = null;
    }
    #start = () => {
        if(!this.#frameId) requestAnimationFrame(this.#animate);
    }
    #animate = () => {
        this.#render();
        this.#frameId = requestAnimationFrame(this.#animate);
    }
    update = current => {
        let instanceIdxs = {};
        for(let i = 0; i < this.scene.children.length; i++){
            if(this.scene.children[i].name) instanceIdxs[this.scene.children[i].name] = { index: i, n: 0 };
        }
        for(let i = 0; i < this.cargoShortcut.length; i++){
            instanceIdxs[this.cargoShortcut[i].lbl].n++;
            this.scene.children[instanceIdxs[this.cargoShortcut[i].lbl].index].setColorAt(this.cargoShortcut[i].instanceBoxIdx, new Color(this.cargoShortcut[i].color));
            
            if(i < current && current !== -1){
                this.scene.children[instanceIdxs[this.cargoShortcut[i].lbl].index].setColorAt(this.cargoShortcut[i].instanceBoxIdx, new Color(this.cargoShortcut[i].darkerColor));
            }else if(current === i) break;
        }
        for(const key in instanceIdxs){
            this.scene.children[instanceIdxs[key].index].count = instanceIdxs[key].n;
            this.scene.children[instanceIdxs[key].index].instanceMatrix.needsUpdate = true;
            this.scene.children[instanceIdxs[key].index].instanceColor.needsUpdate = true;
        }
    }
    #render = () => {
        this.renderer.render(this.scene, this.camera);
        if(this.orientationHelper){
            this.renderer.autoClear = false;
            this.orientationHelper.render( this.renderer );
            this.renderer.autoClear = true;
        }
    }
};
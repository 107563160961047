import CircularProgress from '@mui/material/CircularProgress';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

export const statusIcons = {
    "inactive": <HourglassEmptyIcon color="secondary"/>,
    "finished": <CheckIcon color="secondary"/>,
    "error": <CloseIcon color="secondary"/>,
    "active": <CircularProgress color='secondary' size={24}/>,
    "warning": <WarningIcon color="secondary"/>
};

export default function StatusComponent ({ status, message }) {
    return (
        <div className="w100 flex fspbw fgap-sm">
            {statusIcons[status]}
            {message && <span className="--unit">{message}</span>}
        </div>
    );
};
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { profiles } from '../../util/constants';
import { calculateDistance } from '../../util/util';
import NavigationMap from './NavigationMap';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Alert from '../../UI/Alert';

import './MapDialog.css';

let timeout = null;
const mapFeedback = {
    "inactive": {
        title: "",
        severity: "info"
    },
    "active": {
        title: "Computing",
        severity: "info"
    },
    "error": {
        title: "The estimate used might be incorrect",
        severity: "warning"
    },
    "finished": {
        title: "Distance updated",
        severity: "success"
    }
};

export default function MapDialog (props) {
    const [requestStatus, setRequestStatus] = useState("inactive");
    const [startAnchor, setStartAnchor] = useState([47.233435906153275,6.022695541381864]);
    const [endAnchor, setEndAnchor] = useState([47,6]);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const readyBins = () => {
        let doReq = false;
        let newDistances = props.bins.map(item => item.distMed);
        for(let i = 0; i < props.bins.length; i++){
            //Disabled server request for migration to cloudflare
            //if(props.bins[i].type !== 1 && props.bins[i].type !== 6) doReq = true;
            newDistances[i] = calculateDistancesLocally(props.bins[i]);
        }

        const cachedDistance = props.history.getDistance(startAnchor, endAnchor);
        return {
            distances: newDistances,
            doReq: doReq,
            cachedDistance: cachedDistance
        };
    };

    const handleSubmit = e => {
        if(requestStatus !== "inactive") return null;
        const { distances,/* doReq,*/ cachedDistance } = readyBins();
        setRequestStatus("active");
        clearTimeout(timeout);
        
        if(cachedDistance !== null) return timeout = setTimeout(() => {
            const distArray = prepareDistanceOutput(cachedDistance, distances);
            finalizeComputation(distArray, "finished");
        }, 2000);
        
        //Disabled server request for migration to cloudflare
        //if(doReq) return timeout = setTimeout(() => request(distances), 2000);
        
        timeout = setTimeout(() => finalizeComputation(distances, "finished"), 2000);
    };
    /*const request = distances => {
        axios.post(
            url + "/snna67aba3ns98naj6jaajsnaj48a8aanshsga5anna89a6scalmaka7ap371isn",
            JSON.stringify([
                startAnchor,
                endAnchor
            ]),
                {
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 10000,
            }
        )
        .then(res => {
            const ds = prepareDistanceOutput(Math.round(parseFloat(res.data[2].dist) / 1000), distances);
            finalizeComputation(ds, "finished", res.data);
        })
        .catch(() => finalizeComputation(distances, "error"));
    };*/

    const calculateDistancesLocally = bin => profiles[bin.type].correct_dist * calculateDistance(startAnchor[0], startAnchor[1], endAnchor[0], endAnchor[1]);

    const finalizeComputation = (ds, status, cacheObj) => {
        setRequestStatus(status);
        timeout = setTimeout(() => setRequestStatus("inactive"), 2000);
        props.onUseDistance(ds, cacheObj);
    };

    const prepareDistanceOutput = (computedDistance, distances) => {
        if(isNaN(computedDistance)) return distances;
        const newDistances = distances.map(item => item);
        for(let i = 0; i < props.bins.length; i++){
            if(props.bins[i].type !== 1 && props.bins[i].type !== 6) newDistances[i] = computedDistance;
        }
        return newDistances;
    };

    const handleChange = (anchor, loc) => {
        switch(loc){
            case "start": setStartAnchor(anchor); break;
            case "end": setEndAnchor(anchor); break;
            default: break;
        }
    };
    return (
        [
            <Alert key="map-dialog-0" open={requestStatus !== "inactive"} severity={mapFeedback[requestStatus].severity} message={mapFeedback[requestStatus].title}/>,
            <Dialog
                key="map-dialog-1"
                fullScreen={fullScreen}
                fullWidth
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="map-dialog-title"
            >
                <DialogTitle id="map-dialog-title">
                    Compute driving distance
                </DialogTitle>
                <DialogContent>
                    <NavigationMap startAnchor={startAnchor} endAnchor={endAnchor} handleChange={handleChange}/>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant="text" color="secondary" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleSubmit} disabled={requestStatus !== "inactive"} autoFocus>
                        Compute
                    </Button>
                </DialogActions>
            </Dialog>
        ]
    );
};
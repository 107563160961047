import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useLocation } from "react-router-dom";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ScrollableList from '../../UI/ScrollableList';

import './Step.css';

export default function TripStep (props) {
    let location = useLocation();
    return (
        <div className="step">
            <Stack spacing={1}>
                {location.pathname === "/" ? [
                    <Button onClick={props.handleInterval} key="side-panel-top-0" size="small" variant="contained" startIcon={props.isIntervalPlaying ? <PauseIcon /> : <PlayArrowIcon />} disabled={props.globalDisable}>{props.isIntervalPlaying ? "Pause" : "Packing animation"}</Button>,
                    <Stack key="side-panel-top-1" direction="row" spacing={1}>
                        <Button sx={{flex: "1 1 50%"}} size="small" variant="contained" startIcon={<NavigateBeforeIcon />} onClick={() => props.handleSelectBox(props.currentSelectedBox < 0 ? props.graphic[props.currentSelectedTrip].length - 1 : props.currentSelectedBox - 1)} disabled={props.globalDisable}>Previous</Button>
                        <Button sx={{flex: "1 1 50%"}} size="small" variant="contained" endIcon={<NavigateNextIcon />} onClick={() => props.handleSelectBox(props.currentSelectedBox + 1)} disabled={props.globalDisable}>Next</Button>
                    </Stack>
                    ] :
                    <Button size="small" variant="contained" startIcon={<ViewInArIcon />} onClick={() => {props.handleSelectTrip(props.i); props.handleNav("/")}}>3D model</Button>
                }
                <ScrollableList content={props.item} current={props.currentSelectedBox} onClick={props.handleSelectBox}/>
            </Stack>
        </div>
    );
};
import './Header.css';

export default function Header (props) {
    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();
        props.handleNav("/");
    };
    return (
        <header>
            <h1>
                <a href="/" title="Calculator" onClick={handleClick}>3D bin packer</a>
            </h1>
        </header>
    );
};
import { ntrs } from "./util";

const initialState = { status: "inactive", message: "Please enter/modify options, bin(s) & cargo" }
const errorMessages = [
    "Invalid input",
    "Cargo is too big",
    "Cargo is too heavy",
    "Too many boxes"
];

const errorStateMessage = (errorIdx = 0) => (Number.isInteger(errorIdx) && errorMessages[errorIdx] && errorMessages[errorIdx]) || errorMessages[0]
const activeStateMessage = val => val ? (`${ntrs(val)} box${val === 1 ? "" : "es"} left`) : 'Packing';
const selectedBoxMessage = ({ lbl, dim1, dim2, dim3 }) => `${lbl.toUpperCase()} - ${ntrs(dim1, "mm")} x ${ntrs(dim2, "mm")} x ${ntrs(dim3, "mm")}`;

export const feedbackHandler = (state, value) => {
    switch(state){
        case "inactive": return initialState;
        case "active": return { status: "active", message: activeStateMessage(value) };
        case "error": return { status: "error", message: errorStateMessage(value) };
        case "warning": return { status: "warning", message: "There needs to be at least 1 item" };
        case "finished": return { status: "finished", message: value?.lbl ? selectedBoxMessage(value) : "Success" }
        default: return initialState;
    };
};
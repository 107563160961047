import StatusComponent from "./StatusComponent";

import './CenterPanel.css';

export default function CenterPanel (props) {
    return (
        <div className="center-panel main-grid-center">
            <div className="calculator-status">
                <StatusComponent status={props.calculatorStatus.status} message={props.calculatorStatus.message}/>
            </div>
            {props.children}
        </div>
    );
};
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ntrs, secondsToHms } from "../../util/util";
import {
    StackedBarChart,
    StackedPieChart,
    LineChart
} from '../../BinPacker/charts/Charts';

export default function ResultsPanel ({calculatorResults, currentSelectedTrip}) {

    const currency = calculatorResults?.options?.currency;
    const currentTripData = calculatorResults?.data[currentSelectedTrip];
    return (
        <div className="results-panel inner-center-panel main-grid-inner-center">
            {calculatorResults ?
                <div className="inner-center-step-container">
                    <div className="grid-child-grow-fill">
                        <div className="panel-header">
                            <h2>Details</h2>
                        </div>
                        <div className="step flex fcent">
                            <LineChart data={calculatorResults.financialOverview.binUsage} title="Bin usage" current={currentSelectedTrip}/>
                        </div>
                    </div>
                    <div className="grid-child-grow-medium-xxl">
                        <div className="panel-header">
                            <h2>Trip #{ntrs(currentSelectedTrip + 1, 1)} of {ntrs(calculatorResults.data.length, 1)}</h2>
                        </div>
                        <div className="step panel-group">
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                    <TableRow>
                                            <TableCell component="th" scope="row">Weight</TableCell>
                                            <TableCell>{ntrs(currentTripData.kg, "kg")}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Volume</TableCell>
                                            <TableCell>{ntrs(currentTripData.optiVol, 3)}m<sup>3</sup></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Distance</TableCell>
                                            <TableCell>{ntrs(currentTripData.str.distMed, 1)}km</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Duration</TableCell>
                                            <TableCell>{secondsToHms(calculatorResults.financialData[currentSelectedTrip].duration)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Fuel</TableCell>
                                            <TableCell>{(calculatorResults.financialData[currentSelectedTrip].fuel.cons.full >= 1 ? ntrs(calculatorResults.financialData[currentSelectedTrip].fuel.cons.full, 2) : "<1") + calculatorResults.financialData[currentSelectedTrip].fuel.unit}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Rate</TableCell>
                                            <TableCell>{ntrs(calculatorResults.financialData[currentSelectedTrip].income.rate, 2) + " " + currency}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Costs</TableCell>
                                            <TableCell>{ntrs(calculatorResults.financialData[currentSelectedTrip].costs.total, 2) + " " + currency}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    <div className="grid-child-grow-medium-xxl">
                        <div className="panel-header">
                            <h2 className="--xs">Cost distribution</h2>
                        </div>
                        <div className="step flex fcent">
                            <StackedBarChart data={calculatorResults.financialData[currentSelectedTrip].charts.costs} title="Costs"/>
                        </div>
                    </div>
                    <div className="grid-child-grow-sub-medium grid-child-grow-medium-xxl">
                        <div className="panel-header">
                            <h2 className="--xs">Bin</h2>
                        </div>
                        <div className="step flex fcent">
                            <StackedPieChart data={calculatorResults.financialData[currentSelectedTrip].charts.binUsage} type="outer" title="Bin usage"/>
                        </div>
                    </div>
                    <div className="grid-child-grow-sub-medium grid-child-grow-medium-xxl">
                        <div className="panel-header">
                            <h2 className="--xs">Cargo</h2>
                        </div>
                        <div className="step flex fcent">
                            <StackedPieChart data={calculatorResults.financialData[currentSelectedTrip].charts.packing} type="inner" title="Cargo"/>
                        </div>
                    </div>
                    <div className="grid-child-grow-fill">
                        <div className="panel-header">
                            <h2>Summary</h2>
                        </div>
                        <div className="step panel-group">
                            <h4>Overview - all trips</h4>
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}>Profit estimate</TableCell>
                                            <TableCell><strong>{ntrs(calculatorResults.financialOverview.income.profit, 2) + " " + currency}</strong></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}>Projected income</TableCell>
                                            <TableCell><strong>{ntrs(calculatorResults.financialOverview.income.rate, 2) + " " + currency}</strong></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell rowSpan={1} />
                                            <TableCell>from rate</TableCell>
                                            <TableCell>+{ntrs(calculatorResults.financialOverview.income.rate, 2) + " " + currency}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}>Projected costs</TableCell>
                                            <TableCell>-<strong>{ntrs(calculatorResults.financialOverview.costs.total, 2) + " " + currency}</strong></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell rowSpan={1} />
                                            <TableCell>from fuel</TableCell>
                                            <TableCell>-{ntrs(calculatorResults.financialOverview.costs.fuel, 2) + " " + currency}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell rowSpan={1} />
                                            <TableCell>from insurance</TableCell>
                                            <TableCell>-{ntrs(calculatorResults.financialOverview.costs.insurance, 2) + " " + currency}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell rowSpan={1} />
                                            <TableCell>from maintenance</TableCell>
                                            <TableCell>-{ntrs(calculatorResults.financialOverview.costs.maintenance, 2) + " " + currency}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell rowSpan={1} />
                                            <TableCell>from vehicle payoff</TableCell>
                                            <TableCell>-{ntrs(calculatorResults.financialOverview.costs.vehiclePrice, 2) + " " + currency}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell rowSpan={1} />
                                            <TableCell>from vehicle tax</TableCell>
                                            <TableCell>-{ntrs(calculatorResults.financialOverview.costs.vehicleTax, 2) + " " + currency}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2}>Computed tax rate</TableCell>
                                            <TableCell>{ntrs(((calculatorResults.financialOverview.costs.tax * 100) / Math.max(calculatorResults.financialOverview.costs.total, 0.01)), 1)}%</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            :
                <div className="inner-center-step-container">
                    <div>
                        <div className="panel-header">
                            <h2>Computing...</h2>
                            <p>Result will be displayed shortly</p>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
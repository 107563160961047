import { useRef, useEffect, useState, useCallback } from 'react';
import { routes } from '../../util/routes';
import NavLink from '../../UI/NavLink';
import { handleDonation } from '../../util/donation';
import { useLocation } from "react-router-dom";
import { ntrs } from '../../util/util';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import './Navigation.css';

export default function Navigation (props) {
    const [isMenuOpen, setMenu] = useState(false);

    let location = useLocation();

    const mobileNavRef = useRef(null);

    const handleOutsideClick = useCallback(e => {
        if(mobileNavRef.current && !mobileNavRef.current.contains(e.target)) setMenu(false);
    }, []);

    const handleNavigation = key => {
        setMenu(false);
        props.handleNav(key);
    };

    useEffect(() => {
        if(isMenuOpen) document.addEventListener("mousedown", handleOutsideClick, false);
        else document.removeEventListener("mousedown", handleOutsideClick, false);
    }, [ isMenuOpen, handleOutsideClick ]);

    return (
        <div className="navigation main-grid">
            <div className="main-grid-side --md"></div>
            <nav className="main-grid-center flex fspbw fwrap">
                <div className="--sm">
                    <ul className="flex fwrap">
                        {routes.map((item, i) => <li key={"nav-link-" + i}><NavLink selected={item.key === location.pathname} onClick={props.handleNav} item={item}/></li>)}
                    </ul>
                </div>
                <div className="--sm">
                    <ul className="flex">
                        <li><NavLink onClick={handleDonation} item={{key: "/", title: "Support"}}/></li>
                    </ul>
                </div>
                <div className={`--xs mobile-menu --${isMenuOpen ? "active" : "inactive"}`} ref={mobileNavRef}>
                    <IconButton aria-label="menu" onClick={() => setMenu(!isMenuOpen)}>
                        {!isMenuOpen ?
                            <MenuIcon fontSize="inherit"/>
                            :
                            <CloseIcon fontSize="inherit"/>
                        }
                    </IconButton>
                    {isMenuOpen &&
                        <div className="mobile-nav">
                            <List disablePadding>
                                {routes.map((item, i) =>
                                    <ListItem
                                        key={"nav-link-" + i}
                                        disablePadding
                                        selected={item.key === location.pathname}
                                        onClick={() => handleNavigation(item.key)}
                                        sx={{
                                            "&.Mui-selected": {
                                                backgroundColor: 'var(--clr-secondary)'
                                            }
                                        }}
                                    >
                                        <ListItemButton>
                                            <ListItemText primary={item.title} />
                                        </ListItemButton>
                                    </ListItem>
                                )}
                                <ListItem
                                        disablePadding
                                        onClick={handleDonation}
                                        sx={{
                                            "&.Mui-selected": {
                                                backgroundColor: 'var(--clr-secondary)'
                                            }
                                        }}
                                    >
                                    <ListItemButton>
                                        <ListItemText primary="Support" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </div>
                    }
                </div>
                <div className="--xs">
                    <div className="flex fvcent">
                        <IconButton aria-label="menu" onClick={() => props.handleSelectTrip(props.currentSelectedTrip - 1)} title="Previous trip" disabled={!(props.currentSelectedTrip > 0)}>
                            <NavigateBeforeIcon fontSize="inherit"/>
                        </IconButton>
                        <div>
                            <span className="mobile-trip-handler-label">Trip #{ntrs(props.currentSelectedTrip + 1, 1)}</span>
                        </div>
                        <IconButton aria-label="menu" onClick={() => props.handleSelectTrip(props.currentSelectedTrip + 1)} title="Next trip" disabled={!(props.currentSelectedTrip < props.nTrips - 1)}>
                            <NavigateNextIcon fontSize="inherit"/>
                        </IconButton>
                    </div>
                </div>
            </nav>
            <div className="main-grid-side --md"></div>
        </div>
    );
};
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const severityMapping = {
    inactive: "info",
    active: "info",
    finished: "success",
    success: "success",
    error: "error",
    warning: "warning"
};

export default function Alert ({open, message, severity}) {
    return (
        <Snackbar open={open}>
            <MuiAlert elevation={6} variant="filled" severity={severityMapping[severity] ?? severity}>
                {message}
            </MuiAlert>
        </Snackbar>
    );
};
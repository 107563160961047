export const handleDonation = () => {
    const form = document.createElement("form");
    form.name = "paypal";
    form.method = "post";
    form.action = "https://www.paypal.com/donate";
    form.target = "_top";

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "hosted_button_id";
    input.value = "YWYW5ERE8MS6Q";

    form.appendChild(input);
    document.body.appendChild(form);

    form.submit();
    document.body.removeChild(form);
}
import AddIcon from '@mui/icons-material/Add';

import './AddTile.css';

export default function AddTile ({onClick}) {
    return (
        <div className="add-tile">
            <div className="flex fvcent padding-block padding-inline" tabIndex="0" role="button" onClick={onClick}>
                <div>
                    <AddIcon fontSize="small"/>
                </div>
                <div className="add-tile-label">
                    <strong>ADD</strong>
                </div>
            </div>
        </div>
    );
};
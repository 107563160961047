import './Text.css';

export default function Text ({ text }) {
    return (
        <div className="text-panel inner-center-panel main-grid-inner-center">
            <div className="inner-center-step-container">
                <div className="grid-child-grow-fill">
                    <div className="panel-header">
                        <h2>{text.h}</h2>
                    </div>
                    {text.content.map((item, i) => [
                        item.h3 &&
                        <div className="panel-header" key={"text-step-header" + i}>
                            <h3>{item.h3}</h3>
                        </div>,
                        <div className="step panel-group" key={"text-step-block" + i}>
                            <h4>{item.h}</h4>
                            {item.p && <p>{item.p}</p>}
                            {item.l &&
                                <ul>
                                    {item.l.map((li, j) => <li key={"text-step-item-" + i + "-" + j}>{li}</li>)}
                                </ul>
                            }
                        </div>
                    ])}
                </div>
            </div>
        </div>
    );
};
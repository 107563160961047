import { useRef, useEffect } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import { ntrs } from '../util/util';
import { Virtuoso } from 'react-virtuoso'

import './ScrollableList.css';

const SecondaryText = ({item}) => (
    <span className="list-item-secondary">
        <strong>Size:</strong> {ntrs(item.dim1, "mm") + "x" + ntrs(item.dim2, "mm") + "x" + ntrs(item.dim3, "mm")}<br/>
        <strong>Coords:</strong> {ntrs(item.cox, "mm") + "x" + ntrs(item.coy, "mm") + "x" + ntrs(item.coz, "mm")}
    </span>
);

export default function ScrollableList ({content, current, onClick}) {
    const vRef = useRef(null);

    useEffect(() => vRef.current.scrollToIndex({index: current}), [current]);
    return (
        <div className="scrollable-list">
            <Box>
                <List
                    aria-label="packed cargo"
                    dense={true}
                    subheader={<ListSubheader>Cargo</ListSubheader>}
                >
                    <Virtuoso
                        ref={vRef}
                        style={{ height: `${Math.min(560, content.length * 100)}px` }}
                        totalCount={content.length}
                        itemContent={i => <ListItemButton
                            key={`list-item-packed-cargo-${i}`}
                            selected={current === i}
                            onClick={e => onClick(i)}
                            sx={{
                                borderLeft: `8px solid ${content[i].color}`,
                                "&.Mui-selected": {
                                    backgroundColor: 'var(--clr-primary)'
                                }
                            }}
                        >
                            <ListItemText primary={content[i].lbl.toUpperCase()} secondary={<SecondaryText item={content[i]}/>}/>
                        </ListItemButton>} />
                    
                </List>
            </Box>
        </div>
    );
};
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BinPacker from './BinPacker.js';

const theme = createTheme({
    palette: {
      primary: {
        main: '#333',
        contrastText: '#f7f7f7',
      },
      secondary: {
          main: '#C6371C',
          contrastText: '#f7f7f7',
      },
      mode: "dark"
    },
    typography:{
        fontFamily: "'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    },
});

export default function App () {
    return (
        <ThemeProvider theme={theme}>
            <BinPacker/>
        </ThemeProvider>
    );
};

import {
    randomColor
} from "./colors.js";

export class Options {
    constructor(c = "USD", vat = 0.02, op = false){
        this.currency = c;
        this.vat = vat;
        this.optimizePacking = op;
    }
    load(obj){
        try{
            Object.keys(obj).forEach(key => {
                if(!this[key] === undefined || this[key] === null) throw new Error("invalid");
                this[key] = obj[key];
            });
        }catch(err){
            return null;
        }
    }
}

export class TarItem{
    constructor(td = 1, tv = 1, tp = 1, cd = 1, cp = 1, f = 0){
        this.cargo = false;
        this.td = td;
        this.tv = tv;
        this.tp = tp;
        this.cd = cd;
        this.cp = cp;
        this.forfait = f;
    }
}

export class CargoItem{
    constructor(color = randomColor(), x = 325, y = 168, z = 245, type = "box", strkg = 25, strn = 1){
        this.type = type;
        this.x = x;
        this.z = z;
        this.y = y;
        this.strkg = strkg;
        this.strn = strn;
        this.color = color;
        this._ = "cargo";
    }
    load(obj){
        try{
            Object.keys(obj).forEach(key => {
                if(this[key] === undefined || this[key] === null) throw new Error("invalid");
                this[key] = obj[key];
            });
        }catch(err){
            return null;
        }
    }
}

export class BinItem{
    constructor(x = 1500, y = 1000, z = 1000, type = 0, strkg = 1500, strton = 1500, vp = 20000, carb = "dies", carbp = 1, ins = 700, vt = 300, m = 1000, dm = 100, da = 100000, tar = new TarItem(1.4, 0.99, 0.01, 0.95, 0.96, 20)){
        this.type = type;
        this.x = x;
        this.z = z;
        this.y = y;
        this.strkg = strkg;
        this.strton = strton;
        this.color = "#333";
        this._ = "bin";

        this.vehiclePrice = vp;
        this.carb = carb;
        this.carbPrice = carbp;
        this.insurance = ins;
        this.vehicleTax = vt;
        this.maintenance = m;
        this.distMed = dm;
        this.distAn = da;
        this.dur = 0;

        this.td = tar.td;
        this.tv = tar.tv;
        this.tp = tar.tp;
        this.cd = tar.cd;
        this.cp = tar.cp;
        this.forfait = tar.forfait;
    }
    load(obj){
        try{
            Object.keys(obj).forEach(key => {
                if(this[key] === undefined || this[key] === null) throw new Error("invalid");
                this[key] = obj[key];
            });
        }catch(err){
            return null;
        }
    }
}
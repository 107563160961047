const toRad = Math.PI / 180;
const iDims = (x = 0, y = 0, z = 0) => { return {x: x, y: y, z: z}; };
/* All possible box positions */
export const allXZ = [
    {
        rot: {x: 0, y: 0, z: 0}
    },{
        rot: {x: 0, y: 90 * toRad, z: 0}
    },{
        rot: {x: 90 * toRad, y: 0, z: 0}
    },{
        rot: {x: 90 * toRad, y: 0, z: 90 * toRad}
    },{
        rot: {x: 0, y: 0, z: 90 * toRad}
    },{
        rot: {x: 0, y: 90 * toRad, z: 90 * toRad}
    }
];

export const shadeHexColor = (color, percent) => {
    let f = parseInt(color.slice(1), 16),
        t = percent < 0 ? 0 : 255,
        p = percent < 0 ? percent * -1 : percent,
        R = f >> 16,
        G = (f >> 8) & 0x00FF,
        B = f & 0x0000FF;
    return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
};

export const calculateRotation = (instx, insty, instz, packx, packy, packz) => {
    let obj = iDims();
    const rots = [
        {
            dims: iDims(instx, insty, instz),
            rot: allXZ[0].rot
        },{
            dims: iDims(instz, insty, instx),
            rot: allXZ[1].rot
        },{
            dims: iDims(instx, instz, insty),
            rot: allXZ[2].rot
        },{
            dims: iDims(insty, instz, instx),
            rot: allXZ[3].rot
        },{
            dims: iDims(insty, instx, instz),
            rot: allXZ[4].rot
        },{
            dims: iDims(instz, instx, insty),
            rot: allXZ[5].rot
        }
    ];
    for(const xyz of rots){
        if(xyz.dims.x === packx && xyz.dims.y === packy && xyz.dims.z === packz){
            obj = xyz.rot;
            break;
        }
    }
    return obj;
};
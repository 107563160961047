import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const strMaxLen = 24;

export default function SaveDialog (props) {
    const [name, setName] = useState("");

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = e => {
        const str = e.currentTarget.value.replace(/[^a-z0-9-_]/gi, '')
        if(str.length <= strMaxLen) setName(str);
    };
    return (
        <Dialog
            key="map-dialog-1"
            fullScreen={fullScreen}
            fullWidth
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="save-dialog-title"
        >
            <DialogTitle id="save-dialog-title">
                Save configuration
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add the current configuration to your packing manager. This enables you to later retrieve the current cargo, fleet and/or options.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="saved-config-name"
                    label="Config name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={name}
                    onChange={handleChange}
                    helperText={"Max. " + strMaxLen+ " characters or numbers"}
                    color="secondary"
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="text" color="secondary" onClick={props.onClose}>
                    Back
                </Button>
                <Button autoFocus variant="outlined" color="secondary" onClick={() => props.onSave(name)}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
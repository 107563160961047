import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import SelectInput from '../../UI/SelectInput';
import { getFuelUnit } from '../../util/util';
import { profiles, fuelOptions } from '../../util/constants';
import { types } from '../../util/validators';

import './Step.css';

export default function BinStep (props) {
    const handleChange = (key, val, type) => props.handleSetInput(props.item._, props.i, key, val, type);

    try{
        return (
            <div className="step bin-step">
                <Stack>
                    <h4 className="first-h">Bin details</h4>
                    <Button size={props.size} variant="text" color="secondary" startIcon={<DeleteIcon />} onClick={() => props.onDelete(props.i)}>Delete</Button>
                    <SelectInput
                        label="Type"
                        id={"bin-type-" + props.i}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        options={profiles.map(item => item.type)}
                        value={props.item.type}
                        onChange={val => handleChange("type", val, types.bins.type.type)}
                        useIdx={true}
                        error={!props.inputValidation.bins[props.i].type.status}
                    />
                    <TextField
                        label="Length"
                        id={"bin-x-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.x}
                        onChange={e => handleChange("x", e.target.value, types.bins.x.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].x.status}
                    />
                    <TextField
                        label="Width"
                        id={"bin-z-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.z}
                        onChange={e => handleChange("z", e.target.value, types.bins.z.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].z.status}
                    />
                    <TextField
                        label="Height"
                        id={"bin-y-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.y}
                        onChange={e => handleChange("y", e.target.value, types.bins.y.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].y.status}
                    />
                    <TextField
                        label="Weight"
                        id={"bin-kg-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.strkg}
                        onChange={e => handleChange("strkg", e.target.value, types.bins.strkg.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].strkg.status}
                    />
                    <TextField
                        label="Tonnage"
                        id={"bin-ton-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.strton}
                        onChange={e => handleChange("strton", e.target.value, types.bins.strton.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].strton.status}
                    />
                </Stack>
                <Stack>
                    <h4>Bin costs</h4>
                    <TextField
                        label="Initial price"
                        id={"bin-vp-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{props.options.currency}</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.vehiclePrice}
                        onChange={e => handleChange("vehiclePrice", e.target.value, types.bins.vehiclePrice.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].vehiclePrice.status}
                    />
                    <SelectInput
                        label="Propellant"
                        id={"bin-carb-type-" + props.i}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        options={fuelOptions}
                        value={props.item.carb}
                        onChange={val => handleChange("carb", val, types.bins.carb.type)}
                        useIdx={false}
                        error={!props.inputValidation.bins[props.i].carb.status}
                    />
                    <TextField
                        label="Propellant price"
                        id={"bin-carb-price-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{props.options.currency + getFuelUnit(props.item.carb)}</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.carbPrice}
                        onChange={e => handleChange("carbPrice", e.target.value, types.bins.carbPrice.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].carbPrice.status}
                    />
                    <TextField
                        label="Insurance"
                        id={"bin-insurance-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{props.options.currency}/year</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.insurance}
                        onChange={e => handleChange("insurance", e.target.value, types.bins.insurance.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].insurance.status}
                    />
                    <TextField
                        label="Tax"
                        id={"bin-vtax-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{props.options.currency}/year</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.vehicleTax}
                        onChange={e => handleChange("vehicleTax", e.target.value, types.bins.vehiclePrice.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].vehicleTax.status}
                    />
                    <TextField
                        label="Maintenance"
                        id={"bin-maintenance-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{props.options.currency}/year</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.maintenance}
                        onChange={e => handleChange("maintenance", e.target.value, types.bins.maintenance.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].maintenance.status}
                    />
                    <TextField
                        label="Trip distance"
                        id={"bin-distmed-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">km</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.distMed}
                        onChange={e => handleChange("distMed", e.target.value, types.bins.distMed.type)}
                        type="number"
                        helperText="Manually set the distance of the planned trip or use the map."
                        error={!props.inputValidation.bins[props.i].distMed.status}
                    />
                    <TextField
                        label="Yearly distance"
                        id={"bin-distan-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">km/year</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.distAn}
                        onChange={e => handleChange("distAn", e.target.value, types.bins.distAn.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].distAn.status}
                    />
                </Stack>
                <Stack>
                    <h4>Tarification</h4>
                    <TextField
                        label="Kilometric rate"
                        id={"bin-td-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{props.options.currency}/km</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.td}
                        onChange={e => handleChange("td", e.target.value, types.bins.td.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].td.status}
                    />
                    <TextField
                        label="Volumetric rate"
                        id={"bin-tv-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{props.options.currency}/m<sup>3</sup></InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.tv}
                        onChange={e => handleChange("tv", e.target.value, types.bins.tv.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].tv.status}
                    />
                    <TextField
                        label="Weight rate"
                        id={"bin-tp-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{props.options.currency}/kg</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.tp}
                        onChange={e => handleChange("tp", e.target.value, types.bins.tp.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].tp.status}
                    />
                    <TextField
                        label="Distance coefficient"
                        id={"bin-cd-" + props.i}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.cd}
                        onChange={e => handleChange("cd", e.target.value, types.bins.cd.type)}
                        type="number"
                        helperText=">1 each additional km increases kilometric rate. <1 decreases rate. 1 sets a linear rate (no effect). Set to 0 to completely remove the kilometric rate."
                        error={!props.inputValidation.bins[props.i].cd.status}
                    />
                    <TextField
                        label="Weight coefficient"
                        id={"bin-cp-" + props.i}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.cp}
                        onChange={e => handleChange("cp", e.target.value, types.bins.cp.type)}
                        type="number"
                        helperText=">1 each additional kg increases weight rate. <1 decreases rate. 1 sets a linear rate (no effect). Set to 0 to completely remove the weight rate."
                        error={!props.inputValidation.bins[props.i].cp.status}
                    />
                    <TextField
                        label="Minimum flat rate"
                        id={"bin-minrate-" + props.i}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{props.options.currency}</InputAdornment>,
                        }}
                        size={props.size}
                        margin="dense"
                        color="secondary"
                        value={props.item.forfait}
                        onChange={e => handleChange("forfait", e.target.value, types.bins.forfait.type)}
                        type="number"
                        error={!props.inputValidation.bins[props.i].forfait.status}
                    />
                </Stack>
            </div>
        );
    }catch(err){ return (<div className="step"><span>Preparing data...</span></div>); }
};
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState, useEffect } from 'react';

export default function SelectInput (props) {
    const [value, setValue] = useState(props.value);
  
    const handleChange = e => {
        const val = e.target.value;
        props.onChange(val);
        setValue(val);
    };

    useEffect(() => setValue(props.value), [props.value]);
  
    return (
        <Box>
            <FormControl
                fullWidth
                margin={props.margin}
                color={props.color}
                size={props.size}
                error={props.error}
            >
                <InputLabel id={props.id + "-label"} color={props.color} size={props.size}>{props.label}</InputLabel>
                <Select
                    labelId={props.id + "-label"}
                    id={props.id}
                    value={value}
                    label={props.label}
                    onChange={handleChange}
                    size={props.size}
                    color={props.color}
                >
                    {props.options.map((item, i) => props.useIdx ?
                        <MenuItem key={"option-" + i} value={i}><span className="capitalize">{item}</span></MenuItem>
                        :
                        <MenuItem key={"option-" + i} value={item.key}><span className="capitalize">{item.title}</span></MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>
    );
  }
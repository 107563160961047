import { useState, useEffect, useCallback, startTransition } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import {
    currencies,
    avrg_speeds
} from './util/constants';
import {
    selectProfile,
    sanitize
} from './util/util';
import {
    BinItem,
    CargoItem,
    Options
} from './util/classes';
import {
    defaultCargo
} from './util/defaultConfig';
import {
    HistoryHandler,
    PackingSaveObject
} from './util/history';
import { routes } from './util/routes';
import Header from './components/Header/Header';
import Navigation from './components/Navigation/Navigation';
import FormPanel from './components/SidePanel/FormPanel';
import SelectorPanel from './components/SidePanel/SelectorPanel';
import Scene from './components/Scene/Scene';
import Footer from './components/Footer/Footer';
import MapDialog from './components/Map/MapDialog';
import HistoryDialog from './components/History/HistoryDialog';
import SaveDialog from './components/History/SaveDialog';
import Secondary from './UI/Secondary';
import { runValidation } from './util/validators';
import CenterPanel from './UI/CenterPanel';
import Text from './components/Text/Text';
import { generateCsv } from './util/generateCsv';
import Alert from './UI/Alert';
import ResultsPanel from './components/ResultsPanel/ResultsPanel';
import { changelogText } from './textDump/changelogText';
import { tutorialText } from './textDump/tutorialText';
import { feedbackHandler } from './util/feedback';

import './BinPacker.css';

let warningTimeout = null;
let playPackingInterval = null;
const history = new HistoryHandler();

const createWorker = () => new Worker(new URL('./BinPacker/runComputation.js', import.meta.url));
let worker = createWorker();

export default function BinPacker (props) {
    const [cargo, setCargo] = useState([ ...defaultCargo ]);
    const [bins, setBins] = useState([ new BinItem() ]);
    const [options, setOptions] = useState(new Options());

    const [inputValidation, setInputValidation] = useState(runValidation(options, cargo, bins, true));
    const [calculatorResults, setCalculatorResults] = useState(null);
    const [calculatorStatus, setCalculatorStatus] = useState(feedbackHandler("inactive"));
    const [globalDisable, setGlobalDisable] = useState(true);

    const [currentSelectedTrip, setCurrentSelectedTrip] = useState(0);
    const [currentSelectedBox, setCurrentSelectedBox] = useState(-1);
    const [isIntervalPlaying, setIntervalPlaying] = useState(false);
    const [isCsvErrored, setCsvErrored] = useState(false);

    const [map_popup, setMap_popup] = useState(false);
    const [history_popup, setHistory_popup] = useState(false);
    const [save_popup, setSave_popup] = useState(false);

    const [activeBin, setActiveBin] = useState('none');
    const [activeCargo, setActiveCargo] = useState('none');
    const [hasChanged, setHasChanged] = useState(true);
    const [doUpdateScene, setUpdateScene] = useState(1);

    const [updateWorker, setUpdateWorker] = useState(0);

    let location = useLocation();

    const handleKillWorker = () => {
        worker.terminate();
        worker = createWorker();
        setUpdateWorker(updateWorker + 1);
        setCalculatorStatus(feedbackHandler("inactive"));
    };

    const compute = useCallback(() => {
        try{
            if(playPackingInterval !== null) stopInterval();
            setCalculatorStatus(feedbackHandler("active", 0));

            /* Validate */
            const valRes = runValidation(options, cargo, bins);
            setInputValidation(valRes.inputValidation);
            if(!valRes.validated) throw new Error(0);
            
            /* Pack */
            worker.postMessage({ options, cargo, bins, avrg_speeds });

            setHasChanged(false);
        }catch(err){
            setCalculatorStatus(feedbackHandler("error", parseInt(err?.message) || 0));
        }
    },[ bins, cargo, options ]);

    const handleAddCargo = e => setCargo([ ...cargo, new CargoItem() ]);
    const handleAddBin = e => {
        const nb = new BinItem();
        nb.load(bins[bins.length - 1]);
        setBins([ ...bins, nb ]);
    }

    const handleDeleteCargo = i => {
        if(globalDisable) handleKillWorker();
        if(activeCargo === i) setActiveCargo('none');
        if(cargo.length === 1) return triggerNoDeleteWarning();
        if(cargo[i]){
            const t = [...cargo];
            t.splice(i, 1);
            return setCargo(t);
        }
    };
    const handleDeleteBin = i => {
        if(globalDisable) handleKillWorker();
        if(activeBin === i) setActiveBin('none');
        if(bins.length === 1) return triggerNoDeleteWarning();
        if(bins[i]){
            const t = [...bins];
            t.splice(i, 1);
            return setBins(t);
        }
    };
    const triggerNoDeleteWarning = () => {
        clearTimeout(warningTimeout);
        setCalculatorStatus(feedbackHandler("warning"));
        warningTimeout = setTimeout(() => setCalculatorStatus(feedbackHandler("finished")), 4000);
    };

    const handleInput = (_, index, key, value, type) => {
        if(globalDisable) handleKillWorker();
        startTransition(() => {
            const nextValue = sanitize(value, type);
            if(nextValue === null) return setCalculatorStatus(feedbackHandler("error", 0));
            switch(_){
                case "cargo":
                    let tc = [...cargo];
                    tc[index][key] = nextValue;
                    setCargo(tc);
                    break;
                case "bin":
                    let tb = [...bins];
                    if(key !== "type") tb[index][key] = nextValue;
                    else tb[index] = selectProfile(nextValue);
                    setBins(tb);
                    break;
                case "option":
                    let topts = {...options};
                    topts[key] = nextValue;
                    setOptions(topts);
                    break;
                default: return setCalculatorStatus(feedbackHandler("error", 0));
            }
        });
    };

    const setBinsToDefault = () => {
        if(globalDisable) handleKillWorker();
        let tb = [...bins];
        for(let i = 0; i < tb.length; i++){
            tb[i].carbPrice = currencies[options.currency][tb[i].carb] ?? tb[i].carbPrice;
        }
        let topts = {...options};
        topts.vat = currencies[options.currency].vat;
        setBins(tb);
        setOptions(topts);
    };
    const onUseMapDistance = (d, cacheObj) => {
        if(globalDisable) handleKillWorker();
        if(!Array.isArray(d)) return null;
        let tb = [...bins];
        for(let i = 0; i < tb.length; i++){
            tb[i].distMed = d[i];
        }
        setBins(tb);
        setMap_popup(false);

        if(!!cacheObj && typeof cacheObj === "object") history.set(cacheObj, "map", "add");
    };

    const handleLoadPacking = (obj, doCargo, doBins, doOptions) => {
        if(globalDisable) handleKillWorker();
        setHistory_popup(false);
        try{
            if(doCargo){
                const ncs = new Array(obj.cargo.length);
                for(let i = 0; i < obj.cargo.length; i++){
                    const nc = new CargoItem();
                    nc.load(obj.cargo[i]);
                    ncs[i] = nc;
                }
                setCargo(ncs);
            }
            if(doBins){
                const nbs = new Array(obj.bins.length);
                for(let i = 0; i < obj.bins.length; i++){
                    const nb = new BinItem();
                    nb.load(obj.bins[i]);
                    nbs[i] = nb;
                }
                setBins(nbs);
            }
            if(doOptions){
                const nos = new Options();
                nos.load(obj.options);
                setOptions(nos);
            }
        }catch(err){ return null; }
    };

    const handleSave = name => {
        setSave_popup(false);
        history.set(new PackingSaveObject(name, cargo, bins, options, history.hash()), "packing", "add");
    };

    const handleSelectTrip = tripIdx => {
        if(globalDisable || !calculatorResults) return null;
        if(activeBin !== "none") setActiveBin("none");
        if(activeCargo !== "none") setActiveCargo("none");
        setCalculatorStatus(feedbackHandler("finished"));

        if(tripIdx === currentSelectedTrip || tripIdx >= calculatorResults.graphic.length || isNaN(tripIdx) || tripIdx < 0) return null;
        setCurrentSelectedBox(-1);
        return setCurrentSelectedTrip(tripIdx);
    };
    const handleSelectBox = boxIdx => {
        if(globalDisable || !calculatorResults) return null;
        if(activeBin !== "none") setActiveBin("none");
        if(activeCargo !== "none") setActiveCargo("none");

        if(boxIdx === currentSelectedBox || boxIdx >= calculatorResults.graphic[currentSelectedTrip].length || isNaN(boxIdx) || boxIdx < 0){
            setCalculatorStatus(feedbackHandler("finished"));
            return setCurrentSelectedBox(-1);
        }
        setCalculatorStatus(feedbackHandler(calculatorStatus.status, calculatorResults.graphic[currentSelectedTrip][boxIdx]));
        setCurrentSelectedBox(boxIdx);
    };
    const playPacking = () => {
        if(globalDisable || !calculatorResults) return null;
        if(activeBin !== "none") setActiveBin("none");
        if(activeCargo !== "none") setActiveCargo("none");

        if(playPackingInterval === null){
            setIntervalPlaying(true);
            /* setInterval uses the initial value (always the same), callback catches previous value */
            playPackingInterval = setInterval(() => {
                setCurrentSelectedBox(prevBoxIdx => {
                    if(prevBoxIdx < calculatorResults.graphic[currentSelectedTrip].length - 1) return prevBoxIdx + 1;
                    stopInterval();
                    return -1;
                });
            }, 1000);
        }else if(playPackingInterval !== null) stopInterval();
    };
    const stopInterval = () => {
        clearInterval(playPackingInterval);
        setIntervalPlaying(false);
        playPackingInterval = null;
    };

    let navigate = useNavigate();
    const handleNav = key => navigate(key);

    const handleCsv = () => {
        try{
            generateCsv(calculatorResults.graphic, calculatorResults.data);
        }catch(err){
            setCsvErrored(true);
            setTimeout(() => setCsvErrored(false), 4000);
        }
    };

    const handleUpdateActiveBin = x => {
        if(playPackingInterval !== null) stopInterval();
        if(x === 'none') setCurrentSelectedBox(-1);
        setActiveBin(x);
        setActiveCargo('none');
    };

    const handleUpdateActiveCargo = x => {
        if(playPackingInterval !== null) stopInterval();
        if(x === 'none') setCurrentSelectedBox(-1);
        setActiveCargo(x);
        setActiveBin('none');
    };

    useEffect(() => {
        setHasChanged(true);
        setUpdateScene(state => state + 1);
        if(playPackingInterval !== null) stopInterval();
    }, [ cargo, bins, options ]);

    useEffect(() => {
        if(activeBin === "none" && activeCargo === "none" && hasChanged) startTransition(() => compute());
    }, [cargo, bins, options, compute, activeBin, activeCargo, hasChanged]);

    useEffect(() => {
        worker.onmessage = ({ data }) => {
            if(data.status === "active") return setCalculatorStatus(feedbackHandler("active", data.message));;
            if(!data.res) return setCalculatorStatus(feedbackHandler("error", data.message));
            setCurrentSelectedTrip(0);
            setCalculatorResults(data.res);
            setCalculatorStatus(feedbackHandler("finished"));
        };
        worker.onerror = () => setCalculatorStatus(feedbackHandler("error", 0));
    }, [ updateWorker ]);

    useEffect(() => {
        setActiveBin('none');
        setActiveCargo('none');
        stopInterval();
    }, [ location ]);

    useEffect(() => {
        if(calculatorStatus.status === "finished") setGlobalDisable(false);
        else setGlobalDisable(true);
    }, [ calculatorStatus.status ]);

    useEffect(() => {
        if(currentSelectedBox === -1) setCalculatorStatus(feedbackHandler("finished"));
    }, [ currentSelectedBox ]);

    return (
        <div className="app-container flex fcol">
            <Alert open={calculatorStatus.status === "error" || calculatorStatus.status === "warning"} severity={calculatorStatus.status} message={calculatorStatus.message}/>
            <Alert open={isCsvErrored} severity="error" message="Error"/>
            <HistoryDialog history={history} open={history_popup} onClose={() => setHistory_popup(false)} handleLoadPacking={handleLoadPacking}/>
            <MapDialog history={history} open={map_popup} bins={bins} onClose={() => setMap_popup(false)} onUseDistance={onUseMapDistance}/>
            <SaveDialog open={save_popup} onClose={() => setSave_popup(false)} onSave={handleSave}/>
            <Header handleNav={handleNav}/>
            <Navigation nav={props.nav} handleNav={handleNav} currentSelectedTrip={currentSelectedTrip} handleSelectTrip={handleSelectTrip} nTrips={calculatorResults?.data?.length ?? 0}/>
            <main className="main-grid">
                <FormPanel
                    loc="side"
                    cargo={cargo}
                    bins={bins}
                    nav={props.nav}
                    handleAddCargo={handleAddCargo}
                    handleAddBin={handleAddBin}
                    handleDeleteCargo={handleDeleteCargo}
                    handleDeleteBin={handleDeleteBin}
                    handleInput={handleInput}
                    options={options}
                    setBinsToDefault={setBinsToDefault}
                    handleMapSelection={() => setMap_popup(true)}
                    handleHistorySelection={() => setHistory_popup(true)}
                    inputValidation={inputValidation}
                    handleToggleSave={() => setSave_popup(true)}
                    setActiveBin={handleUpdateActiveBin}
                    activeBin={activeBin}
                    setActiveCargo={handleUpdateActiveCargo}
                    activeCargo={activeCargo}
                    handleKillWorker={handleKillWorker}
                    calculatorStatus={calculatorStatus}
                />
                <CenterPanel calculatorStatus={calculatorStatus}>
                    <Routes>
                        <Route path={routes[0].key} element={
                            <Scene
                                currentSelectedTrip={currentSelectedTrip}
                                calculatorResults={calculatorResults}
                                calculatorStatus={calculatorStatus}
                                handleSelectBox={handleSelectBox}
                                currentSelectedBox={currentSelectedBox}
                                stopInterval={stopInterval}
                                activeBin={activeBin !== "none" ? bins[activeBin] : null}
                                activeCargo={activeCargo !== "none" ? cargo[activeCargo] : null}
                                doUpdateScene={doUpdateScene}
                            />
                        }/>
                        <Route path={routes[1].key} element={
                            <Secondary>
                                <FormPanel
                                    loc="inner-center"
                                    cargo={cargo}
                                    bins={bins}
                                    nav={props.nav}
                                    handleAddCargo={handleAddCargo}
                                    handleAddBin={handleAddBin}
                                    handleDeleteCargo={handleDeleteCargo}
                                    handleDeleteBin={handleDeleteBin}
                                    handleInput={handleInput}
                                    options={options}
                                    setBinsToDefault={setBinsToDefault}
                                    handleMapSelection={() => setMap_popup(true)}
                                    handleHistorySelection={() => setHistory_popup(true)}
                                    inputValidation={inputValidation}
                                    handleToggleSave={() => setSave_popup(true)}
                                    setActiveBin={handleUpdateActiveBin}
                                    activeBin={activeBin}
                                    setActiveCargo={handleUpdateActiveCargo}
                                    activeCargo={activeCargo}
                                    handleKillWorker={handleKillWorker}
                                    calculatorStatus={calculatorStatus}
                                />
                            </Secondary>
                        }/>
                        <Route path={routes[2].key} element={
                            <Secondary>
                                <ResultsPanel
                                    calculatorResults={calculatorResults}
                                    currentSelectedTrip={currentSelectedTrip}
                                />
                            </Secondary>
                        }/>
                        <Route path={routes[3].key} element={
                            <Secondary>
                                <Text text={tutorialText}/>
                            </Secondary>
                        }/>
                         <Route path={routes[4].key} element={
                            <Secondary>
                                <Text text={changelogText}/>
                            </Secondary>
                        }/>
                    </Routes>
                </CenterPanel>
                <SelectorPanel
                    isIntervalPlaying={isIntervalPlaying}
                    handleInterval={() => isIntervalPlaying ? stopInterval() : playPacking()}
                    currentSelectedTrip={currentSelectedTrip}
                    currentSelectedBox={currentSelectedBox}
                    calculatorResults={calculatorResults}
                    handleSelectBox={handleSelectBox}
                    handleSelectTrip={handleSelectTrip}
                    handleCsv={handleCsv}
                    handleNav={handleNav}
                    calculatorStatus={calculatorStatus}
                    globalDisable={globalDisable}
                />
            </main>
            <Footer/>
        </div>
    );
};
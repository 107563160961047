import Pie from '@visx/shape/lib/shapes/Pie';
import { Bar, AreaClosed, Line } from '@visx/shape';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { Text } from '@visx/text';
import { useMemo } from 'react';
import { scaleBand, scaleLinear } from '@visx/scale';
import { curveMonotoneX } from '@visx/curve';
import { AxisLeft } from '@visx/axis';

import './Charts.css';


const value = item => item.value;
const name = item => item.name;

const fontColor = '#f7f7f7';
const fontFamily = "Ubuntu";
const margin = { top: 20, right: 20, bottom: 20, left: 20 };

const outerColors = ['rgba(198, 55, 28, 1)', 'rgba(198, 55, 28, 0.8)', 'rgba(198, 55, 28, 0.6)', 'rgba(198, 55, 28, 0.4)'];
const innerColors = ['rgba(0, 119, 182, 1)', 'rgba(0, 119, 182, 0.8)', 'rgba(0, 119, 182, 0.6)', 'rgba(0, 119, 182, 0.4)'];


export const StackedPieChart = ({data, type, title}) => {
    const donutThickness = 50;
    const colors = type === "inner" ? innerColors : outerColors;
    return (
        <div className="chart-container stacked-pie-chart" title={title}>
            <ParentSize>
                {parent => {
                    const innerWidth = parent.width - margin.left - margin.right;
                    const innerHeight = parent.height - margin.top - margin.bottom;
                    const radius = Math.min(innerWidth, innerHeight) / 2;
                    const centerY = innerHeight / 2;
                    const centerX = innerWidth / 2;
                    return (
                        <svg width={parent.width} height={parent.height}>
                            <Group top={centerY + margin.top} left={centerX + margin.left}>
                                <Pie
                                    data={data}
                                    pieValue={value}
                                    outerRadius={radius}
                                    innerRadius={radius - donutThickness}
                                    cornerRadius={3}
                                    padAngle={0.005}
                                >
                                    {pie =>
                                        pie.arcs.map((arc, i) => {
                                            const [centroidX, centroidY] = pie.path.centroid(arc);
                                            return !arc.data.hide && arc.data.value > 0 && (
                                                <g key={"arc-" + arc.data.symbol + "-" + i}>
                                                    <path d={pie.path(arc)} fill={colors[i]}/>
                                                    <Text
                                                        x={centroidX}
                                                        y={centroidY}
                                                        dy={-10}
                                                        fill={fontColor}
                                                        fontSize="1rem"
                                                        textAnchor="middle"
                                                        pointerEvents="none"
                                                        fontFamily={fontFamily}
                                                    >
                                                        {arc.data.label}
                                                    </Text>
                                                    <Text
                                                        x={centroidX}
                                                        y={centroidY}
                                                        dy={10}
                                                        fill={fontColor}
                                                        fontSize=".8rem"
                                                        textAnchor="middle"
                                                        pointerEvents="none"
                                                        fontFamily={fontFamily}
                                                    >
                                                        {arc.data.value + arc.data.unit + arc.data.add}
                                                    </Text>
                                                </g>
                                            );
                                        })
                                    }
                                </Pie>
                                <Text
                                    className="--sm"
                                    textAnchor="middle"
                                    fontWeight="bold"
                                    fontSize="1.5rem"
                                    dy=".33em"
                                    fill={fontColor}
                                    fontFamily={fontFamily}>
                                    {title}
                                </Text>
                            </Group>
                        </svg>
                    )}
                }
            </ParentSize>
        </div>
    );
};

export const BarChart = ({data, title, width, height}) => {
    const innerHeight = height - margin.top - margin.bottom;
    const innerWidth = width;

    const xScale = useMemo(() => scaleBand({
        range: [0, innerWidth],
        domain: data.map(name),
        padding: 0.4
    }),[innerWidth, data]);
    const yScale = useMemo(() => scaleLinear({
        range: [innerHeight, 0],
        domain: [0, Math.max(...data.map(value))]
    }), [innerHeight, data]);
    return (
        <svg width={width} height={height}>
            {data.map((item, i) => {
                const val = value(item);
                const barWidth = xScale.bandwidth();
                const barHeight = Math.abs(innerHeight - (yScale(val) ?? 0));
                const barX = xScale(name(item));
                const barY = innerHeight - barHeight;

                const anchor = barY < (innerHeight / 2) ? "end" : "start";
                return (
                    <Group key={"bar-data-" + i} top={margin.top} left={margin.left}>
                        <Bar
                            x={barX}
                            y={barY}
                            width={barWidth}
                            height={barHeight}
                            fill={outerColors[0]}
                            offset={"diverging"}
                        ></Bar>
                        <Text
                            x={barX}
                            y={barY}
                            fill={fontColor}
                            fontFamily={fontFamily}
                            fontSize=".8rem"
                            textAnchor={anchor}
                            dx="-.3em"
                            dy="1em"
                            angle={-90}
                        >{name(item)}</Text>
                    </Group>
                );
            })}
        </svg>
    );
};

export const StackedBarChart = ({data, title}) =>
    <div className="chart-container stacked-bar-chart" title={title}>
        <ParentSize>
            {({width, height}) =>
                <BarChart data={data} title={title} width={width} height={height}/>
            }
        </ParentSize>
    </div>;

const StackedLineChart = ({data, title, width, height, current}) => {

    const innerHeight = height - margin.top - margin.bottom;
    const innerWidth = width - margin.left - margin.right;
    const yScale = useMemo(() => scaleLinear({
        range: [innerHeight + margin.top, margin.top],
        domain: [0, (Math.max(...data.map(value)) || 0 ) + innerHeight / 3]
    }), [innerHeight, data]);
    const xScale = useMemo(() => scaleLinear({
        range: [0, innerWidth],
        domain: [0, Math.max(...data.map(name))]
    }),[innerWidth, data]);

    const curPos = {
        x: xScale(name(data[current])),
        y: yScale(value(data[current]))
    };

    return (
        <svg width={width} height={height}>
            <Group left={margin.left} top={margin.top}>
                <AxisLeft
                    strokeDasharray="1,3"
                    stroke={outerColors[0]}
                    strokeOpacity={0.3}
                    pointerEvents="none"
                    scale={yScale}
                />
                <Text x="-60" y="15" transform="rotate(-90)" fontSize="0.8rem">{title}</Text>
                <GridRows
                    left={0}
                    scale={yScale}
                    width={innerWidth}
                    strokeDasharray="1,3"
                    stroke={outerColors[0]}
                    strokeOpacity={0.3}
                    pointerEvents="none"
                />
                <AreaClosed
                    data={data}
                    x={item => xScale(name(item)) ?? 0}
                    y={item => yScale(value(item)) ?? 0}
                    yScale={yScale}
                    strokeWidth={1}
                    stroke={innerColors[0]}
                    fill={innerColors[3]}
                    curve={curveMonotoneX}
                />
                <Group>
                    <Line
                        from={{x: curPos.x, y: margin.top}}
                        to={{x: curPos.x, y: innerHeight + margin.top}}
                        stroke={outerColors[0]}
                        strokeWidth={2}
                        pointerEvents="none"
                        strokeDasharray="5,2"
                    />
                    <circle
                        cx={curPos.x}
                        cy={curPos.y}
                        r={4}
                        fill={innerColors[0]}
                        stroke={outerColors[0]}
                        strokeWidth={2}
                        pointerEvents="none"
                    />
                    <Text
                        textAnchor={current < (data.length * 0.5) ? "start" : "end"}
                        fontSize="0.8rem"
                        dx={curPos.x + (current < (data.length * 0.5) ? 5 : -5)}
                        dy={innerHeight + (margin.top - 5)}
                        fill={fontColor}
                        fontFamily={fontFamily}
                    >
                        {data[current].label}
                    </Text>
                </Group>
            </Group>
        </svg>
    );
};

export const LineChart = ({data, title, current}) =>
    <div className="chart-container line-chart" title={title}>
        <ParentSize>
            {({width, height}) =>  <StackedLineChart data={data} title={title} width={width} height={height} current={current}/>}
        </ParentSize>
    </div>;
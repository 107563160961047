import { useEffect, useRef } from 'react';
import { SceneHandler } from './model/Model.handler';

import './Scene.css';

const sh = new SceneHandler();

export default function Scene ({ activeBin, activeCargo, handleSelectBox, calculatorResults, currentSelectedTrip, currentSelectedBox, doUpdateScene }) {
    const mount = useRef(null);

    useEffect(() => {

        sh.mountScene(calculatorResults?.biggestBin || { x: 100, y: 100, z: 100 }, mount.current);

        return () => {
            sh.unmountScene()
        };
    }, [ calculatorResults ]);

    useEffect(() => {

        const onSelect = e => {
            handleSelectBox(e.value);
        };

        sh.addEventListener("selectbox", onSelect);

        return () => {
            sh.removeEventListener("selectbox", onSelect);
        };        
    }, [ handleSelectBox ]);

    useEffect(() => {

        if(activeBin) sh.mountChildren("bin", activeBin);
        else if(activeCargo) sh.mountChildren("cargo", activeCargo);
        else if(calculatorResults) sh.mountChildren("all", {...calculatorResults, current: currentSelectedTrip});

    }, [ currentSelectedTrip, calculatorResults, activeBin, activeCargo, doUpdateScene ]);

    useEffect(() => {

        !activeBin && !activeCargo && sh.update(currentSelectedBox);

    }, [ currentSelectedBox, activeBin, activeCargo ]);

    return (
        <div className="scene-panel" ref={mount}></div>
    );
};
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';

const formatDate = row => new Date(row.date).toLocaleString();

const Row = ({row, vertical, handleClick}) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const onClick = e => {
        setOpen(!open);
        setAnchorEl(e.currentTarget);
    };
    try{
        return row && (
            <TableRow>
                <TableCell>{row.name || "Packing"}</TableCell>
                <TableCell>{formatDate(row)}</TableCell>
                <TableCell align="right">
                    <IconButton
                        aria-label="action panel"
                        aria-controls={open ? 'action menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        size="small"
                        onClick={onClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setOpen(false)}
                        MenuListProps={{
                        'aria-labelledby': 'action panel',
                        }}
                    >
                        <MenuItem onClick={() => {setOpen(false); handleClick("all", row);}}>
                            <ListItemIcon>
                                <CloudDownloadIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Load all</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {setOpen(false); handleClick("cargo", row);}}>
                            <ListItemIcon>
                                <Inventory2Icon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Load cargo</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {setOpen(false); handleClick("bins", row);}}>
                            <ListItemIcon>
                                <LocalShippingIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Load bins</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => {setOpen(false); handleClick("delete", row);}}>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Remove</ListItemText>
                        </MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
        );
    }catch(err){ return null; }
};

export default function DenseTable ({rows, handleClick}) {
    const theme = useTheme();
    const vertical = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <TableContainer sx={vertical ? null : { maxHeight: 440 }}>
            <Table size="small" aria-label="history table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell align="right"/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => (
                        <Row key={"history-table-row-" + i} row={row} vertical={vertical} handleClick={handleClick}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
import { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import HistoryTable from './HistoryTable';


export default function HistoryDialog (props) {
    const [historyDisplay, setHistoryDisplay] = useState([]);

    const handleSetHistory = useCallback(() => setHistoryDisplay(props.history.getPackings()), [props.history]);

    useEffect(() => handleSetHistory(), [handleSetHistory, props.open]);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClick = (key, obj) => {
        switch(key){
            case "all":
                props.handleLoadPacking(obj, true, true, true);
                break;
            case "cargo":
                props.handleLoadPacking(obj, true, false, true);
                break;
            case "bins":
                props.handleLoadPacking(obj, false, false, true);
                break;
            case "delete":
                const newCache = props.history.set(obj, "packing", "delete");
                setHistoryDisplay(newCache.packing);
                break;
            default: break;
        }
    };

    const handleDeleteAll = () => {
        setHistoryDisplay([]);
        props.history.deleteAll();
    };

    return (
        <Dialog
            key="map-dialog-1"
            fullScreen={fullScreen}
            fullWidth
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="history-dialog-title"
        >
            <DialogTitle id="history-dialog-title">
                Retrieve packing history
            </DialogTitle>
            <DialogContent>
                {Array.isArray(historyDisplay) && historyDisplay.length > 0 ?
                    <HistoryTable rows={historyDisplay} handleClick={handleClick}/>
                    :
                    <DialogContentText>There is no saved configuration</DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="text" color="secondary" onClick={props.onClose}>
                    Back
                </Button>
                <Button autoFocus variant="outlined" color="secondary" onClick={handleDeleteAll}>
                    Delete all
                </Button>
            </DialogActions>
        </Dialog>
    );
};
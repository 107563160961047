import { useMemo, useRef } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'

export default function NavigationMap (props) {

    const startRef = useRef(null);
    const endRef = useRef(null);

    const setStart = useMemo(
        e => ({
            dragend (e) {
                if(startRef.current){
                    const {lat, lng} = startRef.current.getLatLng();
                    props.handleChange([lat, lng], "start");
                }
            },
        }),
        [props],
    );

    const setEnd = useMemo(
        e => ({
            dragend (e) {
                if(endRef.current){
                    const {lat, lng} = endRef.current.getLatLng();
                    props.handleChange([lat, lng], "end");
                }
            },
        }),
        [props],
    );
    return (
        <div className="map-container center-panel">
            <MapContainer center={props.startAnchor} zoom={8} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker ref={startRef} eventHandlers={setStart} position={props.startAnchor} draggable={true}/>
                <Marker ref={endRef} eventHandlers={setEnd} position={props.endAnchor} draggable={true}/>
            </MapContainer>
        </div>
    );
};
export const routes = [
    {
        title: "Calculator",
        key: "/",
    },{
        title: "Data",
        key: "/data"
    },{
        title: "Details",
        key: "/details"
    },{
        title: "Help",
        key: "/help"
    },{
        title: "Changelog",
        key: "/changelog"
    }
];
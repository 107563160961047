const float_ = n => typeof n === "number" && n >= 0;
const int_ = n => Number.isInteger(n) && n >= 0;
const int_not_0 = n => Number.isInteger(n) && n > 0;
const string_ = n => n && typeof n === "string";
const boolean_ = n => n === true || n === false;

export const types = {
    options: {
        currency: {
            type: "string",
            status: true,
            format: string_
        },
        vat: {
            type: "float",
            status: true,
            format: float_
        },
        optimizePacking: {
            type: "boolean",
            status: true,
            format: boolean_
        },
    },
    bins: {
        _: {
            type: "string",
            status: true,
            format: n => n === "bin"
        },
        type: {
            type: "int",
            status: true,
            format: int_
        },
        x: {
            type: "int",
            status: true,
            format: int_not_0
        },
        y: {
            type: "int",
            status: true,
            format: int_not_0
        },
        z: {
            type: "int",
            status: true,
            format: int_not_0
        },
        strkg: {
            type: "int",
            status: true,
            format: int_not_0
        },
        strton: {
            type: "int",
            status: true,
            format: int_not_0
        },
        vehiclePrice: {
            type: "int",
            status: true,
            format: int_
        },
        carb: {
            type: "string",
            status: true,
            format: string_
        },
        carbPrice: {
            type: "float",
            status: true,
            format: float_
        },
        insurance: {
            type: "int",
            status: true,
            format: int_
        },
        vehicleTax: {
            type: "int",
            status: true,
            format: int_
        },
        maintenance: {
            type: "int",
            status: true,
            format: int_
        },
        distMed: {
            type: "int",
            status: true,
            format: int_not_0
        },
        distAn: {
            type: "int",
            status: true,
            format: int_not_0
        },
        dur: {
            type: "float",
            status: true,
            format: float_
        },
        td: {
            type: "float",
            status: true,
            format: float_
        },
        tv: {
            type: "float",
            status: true,
            format: float_
        },
        tp: {
            type: "float",
            status: true,
            format: float_
        },
        cd: {
            type: "float",
            status: true,
            format: float_
        },
        cp: {
            type: "float",
            status: true,
            format: float_
        },
        forfait: {
            type: "int",
            status: true,
            format: int_
        },
        color: {
            type: "string",
            status: true,
            format: string_
        }
    },
    cargo: {
        _: {
            type: "string",
            status: true,
            format: n => n === "cargo"
        },
        color: {
            type: "string",
            status: true,
            format: string_
        },
        type: {
            type: "string",
            status: true,
            format: string_
        },
        x: {
            type: "int",
            status: true,
            format: int_not_0
        },
        y: {
            type: "int",
            status: true,
            format: int_not_0
        },
        z: {
            type: "int",
            status: true,
            format: int_not_0
        },
        strkg: {
            type: "int",
            status: true,
            format: int_not_0
        },
        strn: {
            type: "int",
            status: true,
            format: int_not_0
        }
    }
};

export const runValidation = (options, cargo, bins, quick) => {
    const _t_ = {
        options: types.options,
        cargo: new Array(cargo.length).fill(types.cargo),
        bins: new Array(bins.length).fill(types.bins)
    };
    if(quick) return _t_;

    let validated = true;

    Object.keys(options).forEach(key => {
        _t_.options[key].status = _t_.options[key].format(options[key]);
        if(!_t_.options[key].status) validated = false;
    });

    for(let i = 0; i < _t_.cargo.length; i++){
        let innerVal = true;
        Object.keys(cargo[i]).forEach(key => {
            _t_.cargo[i][key].status = _t_.cargo[i][key].format(cargo[i][key]);
            if(!_t_.cargo[i][key].status) innerVal = false;
        });
        if(!innerVal) validated = false;
    }

    for(let i = 0; i < _t_.bins.length; i++){
        let innerVal = true;
        Object.keys(bins[i]).forEach(key => {
            _t_.bins[i][key].status = _t_.bins[i][key].format(bins[i][key]);
            if(!_t_.bins[i][key].status) innerVal = false;
        });
        if(!innerVal) validated = false;
    }
    return {validated: validated, inputValidation: _t_};
};